import { useNode } from '@craftjs/core';
import React from 'react';
import { ToolbarTextInput } from './ToolbarTextInput';
import { GridLayoutItem } from '@progress/kendo-react-layout';
import { Slider, RadioGroup } from '@progress/kendo-react-inputs';

export const ToolbarItem = ({
  full = false,
  propKey,
  type,
  onChange,
  index,
  data,
  ...props
}) => {
  const {
    actions: { setProp },
    propValue,
  } = useNode((node) => ({
    propValue: node.data.props[propKey],
  }));
  const value = Array.isArray(propValue) ? propValue[index] : propValue;

  return (
    <GridLayoutItem colSpan={full ? 2 : 1}>
      <div className="mb-2">
        {['text', 'color', 'bg', 'number'].includes(type) ? (
          <ToolbarTextInput
            {...props}
            type={type}
            value={value}
            onChange={(value) => {
              setProp((props) => {
                if (Array.isArray(propValue)) {
                  props[propKey][index] = onChange ? onChange(value) : value;
                } else {
                  props[propKey] = onChange ? onChange(value) : value;
                }
              }, 500);
            }}
          />
        ) : type === 'slider' ? (
          <>
            {props.label ? (
              <h4 className="text-sm text-light-gray-2">{props.label}</h4>
            ) : null}
            <Slider
              defaultValue={parseInt(value) || 0}
              min={1}
              max={100}
              step={1}
              onChange={
                (e) => {
                  const value = Math.round(e?.value)
                  setProp((props) => {
                    if (Array.isArray(propValue)) {
                      props[propKey][index] = onChange
                        ? onChange(value)
                        : value;
                    } else {
                      props[propKey] = onChange ? onChange(value) : value;
                    }
                  }, 1000);
                }
              }
            />
          </>
        ) : type === 'radio' ? (
          <>
            {props.label ? (
              <h4 className="text-sm text-light-gray-2">{props.label}</h4>
            ) : null}
            <RadioGroup
              value={value || 0}
              onChange={(e) => {
                const value = e?.value;
                setProp((props) => {
                  props[propKey] = onChange ? onChange(value) : value;
                });
              }}
              data={data}
            />
          </>
        ) : null}
      </div>
    </GridLayoutItem>
  );
};
