import { useNode } from '@craftjs/core';
import cx from 'classnames';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { CustomDatePicker } from '../../../../../../components/form/custom-datePicker';
import { DatePickerSettings } from './DatePickerSettings';
import { Text } from '../Text';
import { FormContext } from '../../../context/form';
import moment from 'moment';
import FieldLabel from '../../../FieldLabel';

const StyledDatePicker = styled.div`
  margin: ${({ margin = [] }) =>
    `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`};
`;

export const DatePicker = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  // Consume form values from the provider.
  // It makes selector components tightly coupled with the parent,
  // but this is by far the most efficient way of doing it.
  const { state } = useContext(FormContext)
  const [values, setValues] = state

  const {
    margin,
    textComponent,
    id,
    label: text,
    disabled,
    required,
    ...otherProps
  } = props;

  const handleOnChange = (e) => {
    // if(id === "user_group"){
    //   if(values.source_of_observation !== undefined){
    //     setValues({
    //     ...values,
    //     [id]: e,
    //        source_of_observation: ""
    //     })
    //   }
    //   else{
    //   setValues({
    //     ...values,
    //     [id]: e.target.value,
    //   })
    // }
    // }else{
    if (values.ohs) {
      if (props.tabID >= 1) {
        if (values.ohs.length < props.tabID) {
          setValues({
            ...values,
            ohs: [...values.ohs, { [id]: e }]
          })
        } else {
          if (values.ohs[props.tabID - 1]?.[id]) {
            let update = values.ohs[props.tabID - 1]
            update[id] = e
            setValues({
              ...values
            })
          } else {
            values.ohs[props.tabID - 1][id] = e
            setValues({
              ...values
            })
          }
        }
      }
    } else {
      if (props.tabID > 0) {
        setValues({
          ...values,
          ohs: [{
            [id]: e
          }]
        })
      } else {
        setValues({
          ...values,
          [id]: e,
        })
      }
      // }
    }
  }

  const handleSetDefaultData = (e) => {
    if (values.ohs) {
      if (props.tabID >= 1) {
        if (values.ohs.length < props.tabID) {
          setValues({
            ...values,
            ohs: [...values.ohs, { [id]: e }]
          })
        }
      }
    } else {
      if (props.tabID > 0) {
        setValues({
          ...values,
          ohs: [{
            [id]: e
          }]
        })
      } else {
        setValues({
          ...values,
          [id]: e,
        })
      }
    }
  }

  useEffect(() => {
    handleSetDefaultData(moment(new Date()).format("YYYY-MM-DD"),)
  }, [])

  useEffect(() => {
    if (required) {
      // if(values.ohs && values.ohs[props.tabID - 1] && (values.ohs[props.tabID - 1][id] === undefined || values.ohs[props.tabID - 1][id] ===
      //   "")){
      //     props.checkValidation(true, `${id} ${props.tabID}`)
      //   }
      //  else if(values.ohs === undefined){
      //     props.checkValidation(true, `${id} ${props.tabID}`)

      //   }else{
      //     props.checkValidation(false, `${id} ${props.tabID}`)
      //   }
      const cases =
        values.ohs === undefined
          ? true
          : values.ohs[props.tabID - 1] &&
            values.ohs[props.tabID - 1][id] !== undefined
            ? values.ohs[props.tabID - 1][id] === ""
              ? true
              : false
            : true;
      if (cases === true) {
        props.checkValidation(true, `${id} ${props.tabID}`)
      } else {
        props.checkValidation(false, `${id} ${props.tabID}`)
      }
    }
  })
  return (
    <StyledDatePicker
      ref={connect}
      className={cx([
        'w-full detail-item-box field-validation__wrapper',
      ])}
      margin={margin}
    >
      <FieldLabel {...textComponent} text={text} required={required} />
      <CustomDatePicker
        {...otherProps}
        format={"DD MMM YYYY"}
        MaxDate={
          new Date(
            new Date().getTime() + 365 * 86400000
          )
        }
        MinDate={
          new Date(new Date().getTime() - 30 * 86400000)
        }
        className={cx([
          'w-full work-textbox',
        ])}
        // onChange={e => {
        //   setValues({
        //     ...values,
        //     [id]: e,
        //   })
        // }}
        onChange={(e) => handleOnChange(moment(e).format("YYYY-MM-DD"))}

        // value={values?.[id]}
        value={props.tabID >= 1 && values.ohs ? typeof (values.ohs[props.tabID - 1]?.[id]) === "string" ? new Date(values.ohs[props.tabID - 1]?.[id]) : values.ohs[props.tabID - 1]?.[id] : ""}
        disabled={disabled}
      />
      {required && props.tabID >= 1 && (
        <>
          {values.ohs === undefined ? (<div className='OHS_FORM_validation'>
            This field is required!</div>) : values.ohs && (values.ohs[props.tabID - 1]?.[id] === undefined || values.ohs[props.tabID - 1]?.[id] === "") && (<div className='OHS_FORM_validation'>
              This field is required!</div>)}
        </>
      )}
    </StyledDatePicker>
  );
};

DatePicker.craft = {
  displayName: 'Datepicker',
  props: {
    margin: ['5', '0', '5', '0'],
    placeholder: 'Enter a value...',
    id: '',
    text: 'Field label',
    textComponent: {
      ...Text.craft.props,
    },
  },
  related: {
    toolbar: DatePickerSettings,
  },
};
