import { useNode } from '@craftjs/core';
import {
  ExpansionPanel,
  ExpansionPanelContent,
  GridLayout,
  GridLayoutItem,
} from '@progress/kendo-react-layout'
import {
  Reveal,
} from '@progress/kendo-react-animation'
import React, { useState } from 'react';

export const ToolbarSection = ({ title, props, summary, children }) => {
  const [expanded, setExpanded] = useState(false);
  const { nodeProps } = useNode((node) => ({
    nodeProps:
      props &&
      props.reduce((res, key) => {
        res[key] = node.data.props[key] || null;
        return res;
      }, {}),
  }));
  return (
    <ExpansionPanel
      title={(
        <div className="w-full">
          <GridLayout gap={{
            rows: 3,
            cols: 3,
          }}>
            <GridLayoutItem row={1} col={1}>
              <h5 className="text-sm text-light-gray-1 text-left font-medium text-dark-gray">
                {title}
              </h5>
            </GridLayoutItem>
            {summary && props ? (
              <GridLayoutItem row={1} col={2}>
                <h5 className="text-light-gray-2 text-sm text-right text-dark-blue">
                  {summary(
                    props.reduce((acc, key) => {
                      acc[key] = nodeProps[key];
                      return acc;
                    }, {})
                  )}
                </h5>
              </GridLayoutItem>
            ) : null}
          </GridLayout>
        </div>
      )}
      expanded={expanded}
      onAction={(event) => {
        setExpanded(!event.expanded);
      }}
    >
      <Reveal>
        {
          expanded
            ? (
              <ExpansionPanelContent>
                <GridLayout gap={{ rows: 1, cols: 1 }}>
                  {children}
                </GridLayout>
              </ExpansionPanelContent>
            )
            : null
        }
      </Reveal>
    </ExpansionPanel>
  );
};
