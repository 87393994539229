import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
// kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  BottomNavigation,
  Drawer,
  DrawerContent,
  DrawerItem,
} from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
// firebase
import { deleteFirebaseToken } from "../firebase";
// icon
import Logo from "../assets/images/Icon/CEreal_Logo_Final.png";
import logout_icon from "../assets/images/Icon/logout.svg";
import logout_hover_icon from "../assets/images/Icon/logout_hover_icon.svg";
import Cross_icon from "../assets/images/Icon/logout_cross_icon.svg";
import NoImage from "../assets/images/Profile/NoImage.png";
// component
import NoPermission from "./noPermission";
// css
import "./navigation.css";
// api
import api from "../config/api";
import config from "../config/config";
// routes
import {
  CCTV_ROUTE,
  CCTV_DETAILS_ROUTE,
  CCTV_SPLIT_ROUTE,
  DASHBOARD_ROUTE,
  ECM_ROUTE,
  ECM_DETAIL_ROUTE,
  MAP_ROUTE,
  MAP_DETAILS,
  CATEGORY_ROUTE,
  INBOX_ROUTE,
  INBOX_DETAIL,
  INBOX_ECM_CASE_REPORT,
  INCIDENT_ROUTE,
  INCIDENT_DETAILS_ROUTE,
  INCIDENT_FORM_ROUTE,
  MORE_ROUTE,
  SITREP_ROUTE,
  SITREP_CREATE_ROUTE,
  SITREP_EDIT_ROUTE,
  SITREP_DETAIL_ROUTE,
  WORKDECLARATION_ROUTE,
  WORKDECLARATION_CREATE_ROUTE,
  WORKDECLARATION_EDIT_ROUTE,
  WORKDECLARATION_DETAIL_ROUTE,
  USER_DETAIL,
  OHSINSPECTION_ROUTE,
  OHS_INSPECTION_CREATE_ROUTE,
  OHS_INSPECTION_EDIT_ROUTE,
  OHS_INSPECTION_DETAIL_ROUTE,
} from "../routes";

const {
  signout,
  getCurrentLoggedInSessionTokenWithPromise,
  getCurrentLoggedInUserWithPromise,
} = require("../config/userPool");

const {
  VIEW_CCTV,
  VIEW_CCTV_LIMITED,
  VIEW_DASHBOARD,
  VIEW_DASHBOARD_LIMITED,
  VIEW_ECM,
  VIEW_ECM_LIMITED,
  VIEW_INBOX,
  VIEW_INBOX_LIMITED,
  VIEW_INCIDENT,
  VIEW_INCIDENT_LIMITED,
  VIEW_MAP,
  VIEW_MY_PROFILE,
  VIEW_SITREP,
  VIEW_SITREP_LIMITED,
  VIEW_WORK_DECLARATION,
  VIEW_WORK_DECLARATION_LIMITED,
  CREATE_INCIDENT,
  CREATE_INCIDENT_LIMITED,
  VIEW_ECM_INCIDENT,
  VIEW_ECM_INCIDENT_LIMITED,
  VIEW_OHS,
  VIEW_OHS_LIMITED,
} = config.permissions;

const { Get_USER_ENDPOINT } = config.api_endpoint;

const Navigation = ({ children, selected, pathname }) => {
  const [picture, setPicture] = useState(null);
  const [loading, setLoading] = useState(false);
  const [shouldAccessCurrentPath, setShouldAccessCurrentPath] = useState(false);
  let drawerExpanded = true;
  let navigationDrawerClassName = "";

  // drawer items
  const items = [
    {
      text: "DASHBOARD",
      icon: "k-i-grid-layout",
      route: DASHBOARD_ROUTE,
      selected: true,
    },
    {
      text: "MAP",
      icon: "k-i-marker-pin-target",
      route: MAP_ROUTE,
    },
    {
      text: "CATEGORY",
      icon: "k-i-group",
      route: CATEGORY_ROUTE,
    },
    {
      text: "INBOX",
      icon: "k-i-email",
      route: INBOX_ROUTE,
    },
    {
      text: "MORE",
      icon: "k-i-more-horizontal",
      route: MORE_ROUTE,
    },
    // {
    //   text: "VIDEO-TEST",
    //   icon: "k-i-video-external",
    //   route: TEST_ROUTE,
    // },
  ];

  const CustomItem = (props) => {
    return (
      <DrawerItem {...props}>
        <div>
          <span className={"k-icon " + props.icon} />
        </div>
        <div className="item-descr-wrap">
          <div>{props.text}</div>
        </div>
      </DrawerItem>
    );
  };
  // bottom navigation props & drawer props are different
  const mobile_items = [
    {
      text: "DASHBOARD",
      id: 1,
      icon: "grid-layout",
      route: DASHBOARD_ROUTE,
    },
    {
      text: "MAP",
      id: 2,
      icon: "marker-pin-target",
      route: MAP_ROUTE,
    },
    {
      text: "CATEGORY",
      id: 3,
      icon: "group",
      route: CATEGORY_ROUTE,
    },
    {
      text: "INBOX",
      id: 4,
      icon: "email",
      route: INBOX_ROUTE,
    },
    {
      text: "MORE",
      id: 5,
      icon: "more-horizontal",
      route: MORE_ROUTE,
    },
  ];
  // new react router stuff
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 769 });
  if (isMobile) {
    drawerExpanded = false;
    navigationDrawerClassName = "navigation-div-transition";
  } else {
    drawerExpanded = true;
    navigationDrawerClassName = "navigation-div";
  }
  const { SAVE_USER_DEVICE_TOKEN_ENDPOINT } = config.api_endpoint;

  const onSelect = (ev) => {
    if (isMobile) {
      navigate(ev.itemTarget.route);
    } else {
      navigate(ev.itemTarget.props.route);
    }
  };

  // splitter
  const [isHovered, setIsHovered] = useState(false);
  const [visible, setVisible] = useState(false);

  const logout = async () => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();

    caches.keys().then((names) => {
      names.forEach((name) => {
        console.log(name);
        caches.delete(name);
      });
    });

    // deleteFirebaseToken();
    // console.log("Deleted Token");
    signout();

    localStorage.removeItem("login");
    localStorage.removeItem("username");
    localStorage.removeItem("session_id");
    localStorage.removeItem("deviceToken_id");

    // if (localStorage.getItem("deviceToken_id")) {
    //   api
    //     .remove(
    //       SAVE_USER_DEVICE_TOKEN_ENDPOINT,
    //       JSON.stringify({
    //         device_id: localStorage.getItem("deviceToken_id"),
    //       }),
    //       token
    //     )
    //     .then((res) => {
    //       console.log("delete device token result: ", res);
    //       localStorage.removeItem("deviceToken_id");
    //     })
    //     .catch((err) => {
    //       console.log("delete device token err", err);
    //       localStorage.removeItem("deviceToken_id");
    //     });
    // }
    localStorage.removeItem("Map_layer");
    window.location.href = "/";
    setVisible(!visible);
  };

  useEffect(() => {
    async function getUser() {
      let currentLoginUser = await getCurrentLoggedInUserWithPromise();
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      console.log("pathname currentLoginUser:", currentLoginUser);
      console.log("pathname token:", token);
      if (currentLoginUser != null) {
        setLoading(true);
        api
          .get(
            Get_USER_ENDPOINT,
            {
              uid: currentLoginUser["cognito:username"],
            },
            token
          )
          .then((res) => {
            console.log(res);
            localStorage.setItem("current_user", JSON.stringify(res.data));
            localStorage.setItem(
              "company",
              res.data["company"][0]["company_name"]
            );
            if (checkIfHasAccess(pathname)) {
              setShouldAccessCurrentPath(true);
            } else {
              setShouldAccessCurrentPath(false);
            }
            setLoading(false);
          });
      }
    }
    getUser();
  }, [pathname]);

  useEffect(() => {
    async function getUser() {
      let currentLoginUser = await getCurrentLoggedInUserWithPromise();
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      console.log("useEffect currentLoginUser:", currentLoginUser);
      console.log("useEffect token:", token);
      setLoading(true);
      if (currentLoginUser != null) {
        api
          .get(
            Get_USER_ENDPOINT,
            {
              uid: currentLoginUser["cognito:username"],
            },
            token
          )
          .then((res) => {
            console.log("Get User in Navigation:", res);
            if ("picture" in res.data) {
              setPicture(res.data.picture);
            }
            localStorage.setItem("current_user", JSON.stringify(res.data));
            localStorage.setItem(
              "company",
              res.data["company"][0]["company_name"]
            );
            if (checkIfHasAccess(pathname)) {
              setShouldAccessCurrentPath(true);
            } else {
              setShouldAccessCurrentPath(false);
            }
            setLoading(false);
          });
      }
    }
    getUser();
  }, []);

  const checkPermission = (_permissions, _user_permissions) => {
    if (_permissions.filter((v) => _user_permissions.includes(v)).length > 0) {
      return true;
    }
    return false;
  };

  const checkIfHasAccess = (module_path) => {
    console.log("checkIfHasAccess:", module_path);
    const permissions = localStorage.getItem("current_user")
      ? JSON.parse(localStorage.getItem("current_user")).permissions
      : [];
    console.log("permissions:", permissions);
    // cctv
    if (
      [CCTV_ROUTE, CCTV_DETAILS_ROUTE, CCTV_SPLIT_ROUTE].indexOf(module_path) >
      -1
    ) {
      let mustHavePermissions = [VIEW_CCTV, VIEW_CCTV_LIMITED];
      return checkPermission(mustHavePermissions, permissions);
    }

    // dashboard
    if ([DASHBOARD_ROUTE].indexOf(module_path) > -1) {
      let mustHavePermissions = [VIEW_DASHBOARD, VIEW_DASHBOARD_LIMITED];
      return checkPermission(mustHavePermissions, permissions);
    }

    // ecm
    if ([ECM_ROUTE, ECM_DETAIL_ROUTE].indexOf(module_path) > -1) {
      let mustHavePermissions = [VIEW_ECM, VIEW_ECM_LIMITED];
      return checkPermission(mustHavePermissions, permissions);
    }

    // inbox
    if (
      [INBOX_ROUTE, INBOX_DETAIL, INBOX_ECM_CASE_REPORT].indexOf(module_path) >
      -1
    ) {
      let mustHavePermissions = [VIEW_INBOX, VIEW_INBOX_LIMITED];
      return checkPermission(mustHavePermissions, permissions);
    }

    // incident
    if ([INCIDENT_ROUTE, INCIDENT_DETAILS_ROUTE].indexOf(module_path) > -1) {
      let mustHavePermissions = [
        VIEW_INCIDENT,
        VIEW_INCIDENT_LIMITED,
        VIEW_ECM_INCIDENT,
        VIEW_ECM_INCIDENT_LIMITED,
      ];
      return checkPermission(mustHavePermissions, permissions);
    }

    if ([INCIDENT_FORM_ROUTE].indexOf(module_path) > -1) {
      let mustHavePermissions = [CREATE_INCIDENT, CREATE_INCIDENT_LIMITED];
      return checkPermission(mustHavePermissions, permissions);
    }

    // map
    if ([MAP_ROUTE, MAP_DETAILS].indexOf(module_path) > -1) {
      let mustHavePermissions = [VIEW_MAP];
      return checkPermission(mustHavePermissions, permissions);
    }

    // profile
    if ([USER_DETAIL].indexOf(module_path) > -1) {
      let mustHavePermissions = [VIEW_MY_PROFILE];
      // return checkPermission(mustHavePermissions, permissions);
      return true;
    }

    // sitrep
    if (
      [
        SITREP_ROUTE,
        SITREP_CREATE_ROUTE,
        SITREP_EDIT_ROUTE,
        SITREP_DETAIL_ROUTE,
      ].indexOf(module_path) > -1
    ) {
      let mustHavePermissions = [VIEW_SITREP, VIEW_SITREP_LIMITED];
      return checkPermission(mustHavePermissions, permissions);
    }

    // work declaration
    if (
      [
        WORKDECLARATION_ROUTE,
        WORKDECLARATION_CREATE_ROUTE,
        WORKDECLARATION_EDIT_ROUTE,
        WORKDECLARATION_DETAIL_ROUTE,
      ].indexOf(module_path) > -1
    ) {
      let mustHavePermissions = [
        VIEW_WORK_DECLARATION,
        VIEW_WORK_DECLARATION_LIMITED,
      ];
      return checkPermission(mustHavePermissions, permissions);
    }

    if (
      [
        OHSINSPECTION_ROUTE,
        OHS_INSPECTION_CREATE_ROUTE,
        OHS_INSPECTION_EDIT_ROUTE,
        OHS_INSPECTION_DETAIL_ROUTE,
      ].indexOf(module_path) > -1
    ) {
      let mustHavePermissions = [VIEW_OHS, VIEW_OHS_LIMITED];
      return checkPermission(mustHavePermissions, permissions);
    }

    return true;
  };

  return (
    <div>
      {visible && (
        <Dialog
          onClose={() => setVisible(!visible)}
          style={{ borderRadius: "8px", zIndex: "100020" }}
        >
          <span
            style={{ float: "right", cursor: "pointer", paddingTop: "15px" }}
          >
            <img src={Cross_icon} onClick={() => setVisible(!visible)} />
          </span>
          <p className="logout_popup_box">Are you sure you want to logout?</p>
          <div className="logout_popup_action_box">
            <button
              className="logout_popup_action_button"
              onClick={() => setVisible(!visible)}
              style={{ color: "#EB7930", background: "#FFFFFF" }}
            >
              No, cancel
            </button>
            <button
              className="logout_popup_action_button"
              onClick={logout}
              style={{ background: "#EB7930", color: "white" }}
            >
              Yes, logout
            </button>
          </div>
        </Dialog>
      )}
      <div>
        <div className={isMobile ? "custom-toolbar hide" : "custom-toolbar"}>
          <span className="cereal-title">
            <img src={Logo} style={{ width: "110.77px", height: "40px" }} />
          </span>
          <span
            className="cereal-title custom_toolbar_profile"
            style={{ cursor: "pointer" }}
          >
            <img
              src={picture != null ? picture : NoImage}
              style={{
                width: "45px",
                height: "45px",
                borderRadius: "100%",
              }}
              onClick={() => navigate(USER_DETAIL)}
            />
            <span className="logout_button">
              <p onClick={() => navigate(USER_DETAIL)}>
                {localStorage.getItem("name") || "User"}
              </p>{" "}
              <img
                src={isHovered ? logout_hover_icon : logout_icon}
                style={{ marginLeft: "10px" }}
                onClick={() => setVisible(!visible)}
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
              />
            </span>
          </span>
        </div>
        <Drawer
          expanded={drawerExpanded}
          position={"start"}
          mode={"push"}
          items={items.map((item) => ({
            ...item,
            selected: item.text.toLowerCase() === selected.toLowerCase(),
          }))}
          item={CustomItem}
          onSelect={onSelect}
          className={navigationDrawerClassName}
        >
          <DrawerContent
            className={isMobile ? "mobile-content" : "desktop-content"}
          >
            {loading ? (
              <Loader className="main-loader" type="infinite-spinner" />
            ) : !loading && shouldAccessCurrentPath ? (
              <>{children}</>
            ) : (
              <NoPermission />
            )}
            <div className="confidentail_box">
              CONFIDENTIAL. DO NOT SHARE WITHOUT AUTHORISED PERMISSION.
            </div>
          </DrawerContent>
        </Drawer>
        <BottomNavigation
          positionMode={"fixed"}
          items={mobile_items.map((item) => ({
            ...item,
            selected: item.text.toLowerCase() === selected.toLowerCase(),
          }))}
          onSelect={onSelect}
          className={!isMobile ? "hide" : ""}
        />
      </div>
      {/* {isMobile ? (
        <div>
          <div className="mobile-content">
            {loading ? (
              <Loader className="main-loader" type="infinite-spinner" />
            ) : !loading && shouldAccessCurrentPath ? (
              <>{children}</>
            ) : (
              <NoPermission />
            )}
          </div>
          <BottomNavigation
            positionMode={"fixed"}
            items={mobile_items.map((item) => ({
              ...item,
              selected: item.text.toLowerCase() === selected.toLowerCase(),
            }))}
            onSelect={onSelect}
          />
        </div>
      ) : (
        <div style={{ background: "#F5F5F5" }}>
          <div className="custom-toolbar">
            <span className="cereal-title">
              <img src={Logo} style={{ width: "110.77px", height: "40px" }} />
            </span>
            <span
              className="cereal-title custom_toolbar_profile"
              style={{ cursor: "pointer" }}
            >
              <img
                src={picture != null ? picture : NoImage}
                style={{
                  width: "45px",
                  height: "45px",
                  borderRadius: "100%",
                }}
                onClick={() => navigate(USER_DETAIL)}
              />
              <span className="logout_button">
                <p onClick={() => navigate(USER_DETAIL)}>
                  {localStorage.getItem("name") || "User"}
                </p>{" "}
                <img
                  src={isHovered ? logout_hover_icon : logout_icon}
                  style={{ marginLeft: "10px" }}
                  onClick={() => setVisible(!visible)}
                  onMouseOver={() => setIsHovered(true)}
                  onMouseOut={() => setIsHovered(false)}
                />
              </span>
            </span>
          </div>
          <Drawer
            expanded={true}
            position={"start"}
            mode={"push"}
            items={items.map((item) => ({
              ...item,
              selected: item.text.toLowerCase() === selected.toLowerCase(),
            }))}
            item={CustomItem}
            onSelect={onSelect}
            className="navigation-div"
          >
            <DrawerContent style={{ background: "#F5F5F5" }}>
              {loading ? (
                <Loader className="main-loader" type="infinite-spinner" />
              ) : !loading && shouldAccessCurrentPath ? (
                <>{children}</>
              ) : (
                <NoPermission />
              )}
            </DrawerContent>
          </Drawer>
        </div>
      )} */}
    </div>
  );
};

export default Navigation;
