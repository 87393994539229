import React from "react";
// logo
import SearchLogo from "../../../assets/images/cctv/search.svg";
import ResetLogo from "../../../assets/images/cctv/reset_cross.svg";
// css
import "../ohs.css";

const OHSListSearch = ({
  onChangeFilter,
  filterValue,
  filterApply,
  filterClear,
  keyDownHandler,
}) => {
  return (
    <React.Fragment>
      <div className="OHS-list-search-div">
        <img
          className="OHS-list-icon"
          src={SearchLogo}
          onClick={(e) => filterApply()}
        />
        <input
          className="textbox"
          type={"text"}
          value={filterValue.search_text}
          placeholder="CONTR-XXXXXXX, PLTC-XXXXXXX"
          onKeyDown={keyDownHandler}
          onChange={(e) => {
            onChangeFilter(e.target.value, "search_text");
          }}
        ></input>
        {filterValue.search_text.length > 0 && (
          <img
            className="OHS-list-icon OHS-list-reset-icon"
            src={ResetLogo}
            onClick={() => filterClear(true)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default OHSListSearch;
