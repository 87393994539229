import { useNode } from '@craftjs/core';
import cx from 'classnames';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import {
  TextBox,
} from "@progress/kendo-react-inputs";
import { AutoUserSettings } from './AutoUserSettings';
import { Text } from '../Text';
import { FormContext } from '../../../context/form';

const StyledAutoUser = styled.div`
  margin: ${({ margin = [] }) =>
    `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`};
`;

export const AutoUser = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  // Consume form values from the provider.
  // It makes selector components tightly coupled with the parent,
  // but this is by far the most efficient way of doing it.
  const { state, userData } = useContext(FormContext)
  const [values, setValues] = state

  // This is a sub optimal way of retrieving user details.
  // TODO: User authentication kit, such as react-auth-kit.
  const currentUser = userData?.data?.name

  const {
    margin,
    textComponent,
    id,
    label: text,
    checkIsShow,
    setAuto,
    ...otherProps
  } = props;

  const checkToShow = () =>{
    console.log(checkIsShow && values?.[checkIsShow.field] === undefined);
    if(checkIsShow && values?.[checkIsShow.field] === undefined) return false
    else return true
  }

  useEffect(() => {
    if (setAuto && currentUser && !values?.[id]) {
      setValues({
        ...values,
        [id]: currentUser,
      })
    }
  }, [values, id, currentUser, setValues])

  console.log(checkToShow());


  return checkToShow() ? (
    <StyledAutoUser
      ref={connect}
      className={cx([
        'w-full detail-item-box field-validation__wrapper',
      ])}
      margin={margin}
    >
      <div className="detail-item-label">
        <Text {...textComponent} text={text} />
      </div>
      <TextBox
        {...otherProps}
        className={cx([
          'w-full work-textbox',
        ])}
        value={values?.[id]}
        readOnly
      />
    </StyledAutoUser>
  ): null
};

AutoUser.craft = {
  displayName: 'Text Field',
  props: {
    margin: ['5', '0', '5', '0'],
    placeholder: 'Enter a value...',
    id: '',
    text: 'Field label',
    textComponent: {
      ...Text.craft.props,
    },
  },
  related: {
    toolbar: AutoUserSettings,
  },
};
