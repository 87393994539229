import { useNode } from '@craftjs/core';
import cx from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import jsonPath from "jsonpath";
import { ComboBoxFieldSettings } from './ComboBoxFieldSettings';
import { Text } from '../Text';
import { FormContext } from '../../../context/form';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { getParamsFromQuery, getUrlFromPath } from '../../utils/url';
import api from '../../../../../../config/api';
import FieldLabel from '../../../FieldLabel';

const StyledComboBoxField = styled.div`
  margin: ${({ margin = [] }) =>
    `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`};
`;

export const ComboBoxField = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  // Consume form values from the provider.
  // It makes selector components tightly coupled with the parent,
  // but this is by far the most efficient way of doing it.
  const { state, token, currentUser } = useContext(FormContext)
  const [values, setValues] = state

  const {
    margin,
    textComponent,
    id,
    dataItemKey = 'id',
    textField = 'label',
    label: text,
    options: sourceOptions = [],
    apiPath: sourceApiPath,
    jsonPath: sourceJsonPath,
    disabled,
    required,
  } = props;

  const [filter, setFilter] = useState('')

  // asynchronous options state from the API
  const [asyncOptions, setAsyncOptions] = useState([])// Handle dynamic apiPath for dropdown list

  const dynamicApiPath = useMemo(() => {
    // Retrieve the first match from the rules for the apiPath.
    const pathRules = Object.entries(sourceApiPath?.rules?.[0] || {})
    const [field, options] = pathRules?.[0] || []
    // Get the appropriate dynamic apiPath
    return options?.[values?.[field]]
  }, [sourceApiPath, values])

  const apiPath = useMemo(() => {
    // Simple apiPath uses string as its value
    if (typeof sourceApiPath === 'string') {
      return sourceApiPath
    }

    // Dynamic apiPath uses a 'rules' object as its value
    else if (sourceApiPath?.rules) {
      return dynamicApiPath
    }

    // Else return undefined
  }, [sourceApiPath, dynamicApiPath])

  useEffect(() => {
    // If options is empty and it has apiPath defined
    // it means that the options should be loaded asynchronously

    // Effect cleanup flag
    let optionsLoaded = false
    const loadOptions = async () => {
      try {
        const endpointUrl = getUrlFromPath(apiPath)
        const endpoint = endpointUrl?.pathname || apiPath
        let inputs = await api.get(endpoint, {
          uid: currentUser["cognito:username"],
          // Append parameters from the endpointUrl's query
          ...getParamsFromQuery(endpointUrl)
        }, token)

        // Extract data with jsonPath if defined
        if (sourceJsonPath) {
          inputs = jsonPath.query(inputs, sourceJsonPath)
        }

        // If the API returns empty array, create a default option;
        // This is to prevents the app from reloading the API
        if (inputs?.length === 0) {
          inputs = [{
            [dataItemKey]: '',
            [textField]: 'Not Available'
          }]
        }
        setAsyncOptions(inputs)
      } catch {
        // TODO: Catch exception
      } finally {
        optionsLoaded = true
      }
    }

    if (
      // load if it has not been loaded yet
      !optionsLoaded &&
      // and it has empty options from the source
      sourceOptions.length === 0 &&
      // and it has not populated the asynchronous options yet
      asyncOptions.length === 0 &&
      // and the apiPath is valid
      !!apiPath
    ) {
      loadOptions()
    }

    // Clean up asynchronous operation
    return () => {
      optionsLoaded = true
    }
  }, [sourceOptions, asyncOptions, apiPath, sourceJsonPath, dataItemKey, textField, token, currentUser])

  useEffect(() => {
    // Reset async options when dynamic path changes
    // so that it re-retrieve the options
    setAsyncOptions([])
  }, [dynamicApiPath])

  const options = useMemo(() => {
    const filterByQuery = opt => {
      return opt?.[textField].toLowerCase().includes(filter.toLowerCase())
    }

    // If local options are available, use it
    if (sourceOptions.length > 0) {
      return sourceOptions.filter(filterByQuery)
    }

    // Otherwise use options loaded remotely
    return asyncOptions.filter(filterByQuery)
  }, [sourceOptions, asyncOptions, filter, textField])


  const handleOnChange = (e) => {
    // if(id === "user_group"){
    //   if(values.source_of_observation !== undefined){
    //     setValues({
    //     ...values,
    //     [id]: e.target.value,
    //        source_of_observation: ""
    //     })
    //   }
    //   else{
    //   setValues({
    //     ...values,
    //     [id]: e.target.value,
    //   })
    // }
    // }else{
    if (e.target.value === null) return

    if (values.ohs) {
      if (props.tabID >= 1) {
        if (values.ohs.length < props.tabID) {
          setValues({
            ...values,
            ohs: [...values.ohs, { [id]: e.target.value }]
          })
        } else {
          if (values.ohs[props.tabID - 1]?.[id]) {
            let update = values.ohs[props.tabID - 1]
            update[id] = e.target.value
            setValues({
              ...values
            })
          } else {
            values.ohs[props.tabID - 1][id] = e.target.value
            setValues({
              ...values
            })
          }
        }
      }
    } else {
      if (props.tabID > 0) {
        setValues({
          ...values,
          ohs: [{
            [id]: e.target.value
          }]
        })
      } else {

        setValues({
          ...values,
          [id]: e.target.value
        })
      }
      // }
    }
  }
  useEffect(() => {
    // if(isVisible){
    if (required) {
      // if(values.ohs && values.ohs[props.tabID - 1] && values.ohs[props.tabID - 1][id] && (values.ohs[props.tabID - 1][id].length > 0)){
      //   props.checkValidation(false, `${id} ${props.tabID}`)
      // }else{
      //   props.checkValidation(true, `${id} ${props.tabID}`)
      // }
      // if(values.ohs && values.ohs[props.tabID - 1] && (values.ohs[props.tabID - 1][id] === undefined || values.ohs[props.tabID - 1][id] ===
      // "")){
      //   props.checkValidation(true, `${id} ${props.tabID}`)
      // }
      // else if(values.ohs === undefined){
      //   props.checkValidation(true, `${id} ${props.tabID}`)
      // }
      const cases =
        values.ohs === undefined
          ? true
          : values.ohs[props.tabID - 1] &&
            values.ohs[props.tabID - 1][id] !== undefined
            ? values.ohs[props.tabID - 1][id] === ""
              ? true
              : false
            : true;
      if (cases === true) {
        props.checkValidation(true, `${id} ${props.tabID}`)
      } else {
        props.checkValidation(false, `${id} ${props.tabID}`)
      }
    }
    // }
  })
  return (
    <StyledComboBoxField
      ref={connect}
      className={cx([
        'w-full detail-item-box field-validation__wrapper',
      ])}
      margin={margin}
    >
      <FieldLabel {...textComponent} text={text} required={required} />
      <div className="w-full comboxBOXID">

        <ComboBox
          // onChange={(e) => {
          //   setValues({
          //     ...values,
          //     [id]: e?.target?.value || '',
          //   })
          // }}
          onChange={handleOnChange}
          data={options}
          value={options.find(
            (i) => i?.[dataItemKey] === (props.tabID >= 1 && values.ohs && values.ohs[props.tabID - 1]?.[id] && values.ohs[props.tabID - 1]?.[id].id)
          )}
          textField={textField}
          dataItemKey={dataItemKey}
          defaultValue={-1}
          filterable={true}
          onFilterChange={(e) =>
            setFilter(e?.filter?.value)
          }
          disabled={disabled}
        />
        {required && props.tabID >= 1 && (
          <>
            {
              values.ohs === undefined ? (
                <div className="OHS_FORM_validation">This field is required!</div>
              ) : values.ohs[props.tabID - 1] &&
                values.ohs[props.tabID - 1][id] !== undefined ? (
                values.ohs[props.tabID - 1][id] === "" ? (
                  <div className="OHS_FORM_validation">This field is required!</div>
                ) : null
              ) : (
                <div className="OHS_FORM_validation">This field is required!</div>
              )
            }
            {/* {values.ohs === undefined ?(<div className='OHS_FORM_validation'>
        This field is required!</div>) :  values.ohs && values.ohs[props.tabID - 1]?.[id] === null && (<div className='OHS_FORM_validation'>
        This field is required!</div>)} */}
          </>
        )}
      </div>
    </StyledComboBoxField>
  );
};

ComboBoxField.craft = {
  displayName: 'Combobox Field',
  props: {
    margin: ['5', '0', '5', '0'],
    placeholder: 'Enter a value...',
    id: '',
    text: 'Field label',
    textComponent: {
      ...Text.craft.props,
    },
  },
  related: {
    toolbar: ComboBoxFieldSettings,
  },
};
