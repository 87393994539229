import React, { useState } from 'react';
import './index.css';
import { PanelSettings } from './PanelSettings';
import { Resizer } from '../Resizer';
import { Text } from '../Text';
import ProgressIcon from '../../../../../../assets/images/Sitrep/Inprogress.svg';

const defaultProps = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  fillSpace: 'no',
  padding: ['0', '0', '0', '0'],
  margin: ['0', '0', '0', '0'],
  background: { r: 200, g: 200, b: 200, a: 1 },
  color: { r: 0, g: 0, b: 0, a: 1 },
  shadow: 0,
  radius: 0,
  gap: 0,
  width: '100%',
  height: 'auto',
  text: 'Panel',
};

export const Panel = (props) => {
  props = {
    ...defaultProps,
    ...props,
  };
  const {
    flexDirection,
    alignItems,
    justifyContent,
    fillSpace,
    background,
    color,
    padding,
    margin,
    shadow,
    radius,
    text,
    textComponent,
    children,
  } = props;
  const [isOpen, setIsOpen] = useState(true);
  return (
    <Resizer
      propKey={{ width: 'width', height: 'height' }}
      style={{
        justifyContent,
        flexDirection,
        alignItems,
        background: `rgba(${Object.values(background)})`,
        color: `rgba(${Object.values(color)})`,
        padding: `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`,
        margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
        boxShadow:
          shadow === 0
            ? 'none'
            : `0px 3px 100px ${shadow}px rgba(0, 0, 0, 0.13)`,
        borderRadius: `${radius}px`,
        flex: fillSpace === 'yes' ? 1 : 'unset',
      }}
    >
      <div className="selector--panel_item w-full mb-4" onClick={() => setIsOpen(!isOpen)}>
        <div className="selector--panel_item_inner_box">
          <img
            src={ProgressIcon}
            alt=""
          />
          <div className="selector--panel_item_inner_text">
            <Text {...textComponent} text={text} />
          </div>
        </div>
      </div>
      {
        isOpen
          ? (
            <div className="detail-row w-full">
              {children}
            </div>
          )
          : null
      }
    </Resizer>
  );
};

Panel.craft = {
  displayName: 'Panel',
  props: {
    ...defaultProps,
    textComponent: {
      ...Text.craft.props,
      textAlign: 'center',
    },
  },
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: PanelSettings,
  },
};
