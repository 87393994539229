import { useNode } from '@craftjs/core';
import cx from 'classnames';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { RadioFieldSettings } from './RadioFieldSettings';
import { Text } from '../Text';
import { FormContext } from '../../../context/form';
import { RadioButton } from '@progress/kendo-react-inputs';
import FieldLabel from '../../../FieldLabel';

const StyledRadioField = styled.div`
  margin: ${({ margin = [] }) =>
    `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`};
`;

export const RadioField = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  // Consume form values from the provider.
  // It makes selector components tightly coupled with the parent,
  // but this is by far the most efficient way of doing it.
  const { state } = useContext(FormContext)
  const [values, setValues] = state

  const {
    margin,
    textComponent,
    id,
    label: text,
    options = [],
    disabled,
    required,
  } = props;
  const handleOnChange = (e) => {
    // if(id === "user_group"){
    //   if(values.source_of_observation !== undefined){
    //     setValues({
    //     ...values,
    //     [id]: e.target.value,
    //        source_of_observation: ""
    //     })
    //   }
    //   else{
    //   setValues({
    //     ...values,
    //     [id]: e.target.value,
    //   })
    // }
    // }else{

    if (values.ohs) {
      if (props.tabID >= 1) {
        if (values.ohs.length < props.tabID) {
          setValues({
            ...values,
            ohs: [...values.ohs, { [id]: e }]
          })
        } else {
          if (values.ohs[props.tabID - 1]?.[id]) {
            let update = values.ohs[props.tabID - 1]
            update[id] = e
            setValues({
              ...values
            })
          } else {
            values.ohs[props.tabID - 1][id] = e
            setValues({
              ...values
            })
          }
        }
      }
    } else {
      if (props.tabID > 0) {
        setValues({
          ...values,
          ohs: [{
            [id]: e
          }]
        })
      } else {

        setValues({
          ...values,
          [id]: e,
        })
      }
      // }
    }
  }
  return (
    <StyledRadioField
      ref={connect}
      className={cx([
        'w-full detail-item-box field-validation__wrapper',
      ])}
      margin={margin}
    >
      <FieldLabel {...textComponent} text={text} required={required} />
      <div className="detail-item detail-radio-div w-full flex gap-4">
        {
          options.map(option => (

            <div className="width-auto min-w-[160px]">
              <RadioButton
                key={option}
                className="details-radio"
                label={option}
                name={id}
                // onChange={(e) =>
                //   setValues({
                //     ...values,
                //     [id]: option,
                //   })
                // }
                onChange={() => handleOnChange(option)}
                // checked={
                //   values?.[id] === option
                //     ? true
                //     : false
                // }
                checked={props.tabID >= 1 && values.ohs && values.ohs[props.tabID - 1]?.[id] === option ? true : false}

                disabled={disabled}
              />
            </div>
          ))
        }
      </div>
    </StyledRadioField>
  );
};

RadioField.craft = {
  displayName: 'Radio Field',
  props: {
    margin: ['5', '0', '5', '0'],
    placeholder: 'Enter a value...',
    id: '',
    text: 'Field label',
    textComponent: {
      ...Text.craft.props,
    },
  },
  related: {
    toolbar: RadioFieldSettings,
  },
};
