import { useNode } from '@craftjs/core';
import cx from 'classnames';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { CheckListItemSettings } from './CheckListItemSettings';
import { Text } from '../Text';
import { FormContext } from '../../../context/form';
import { RadioButton, TextArea } from '@progress/kendo-react-inputs';
import evaluateVisibility from '../../utils/evaluateVisibility';
import FieldLabel from '../../../FieldLabel';
import evaluateValidity from '../../utils/evaluateValidity';
import { isEqual } from '../../utils/equality';

const StyledCheckListItem = styled.div`
  margin: ${({ margin = [] }) =>
    `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`};
  h2 {
    text-decoration: underline;

    &::before {
      counter-increment: section;
      content: counter(section) ". ";
    }
  }
`;

export const CheckListItem = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  // Consume form values from the provider.
  // It makes selector components tightly coupled with the parent,
  // but this is by far the most efficient way of doing it.
  const { state, recordState, errorsState } = useContext(FormContext)
  const [recordDetail] = recordState
  const [values, setValues] = state
  const [errors, setErrors] = errorsState

  // check list item holds its value in an object format of:
  // { selected, remark }

  const {
    margin,
    textComponent,
    label: text,
    items = [],
    visibility = [],
    disabled,
    tabID,
    required,
  } = props;

  const { isVisible } = evaluateVisibility({ values, visibility, tabID, recordDetail })

  // TODO: Decouple from transaction level.
  const handleOnChange = (id, option, type, listLabel) => {
    if (values.ohs) {
      if (tabID >= 1) {
        let update = { ...(values.ohs[tabID - 1]?.checklist || {}) };
        update[id] = {
          ...update[id],
          label: listLabel,
          [type]: option,
        };

        values.ohs[tabID - 1].checklist = update;

        setValues({
          ...values,
        });
      }
    }
  }

  const helpTextProps = {
    fontWeight: 'normal',
    fontStyle: 'italic',
    fontSize: 13,
    fontFamily: 'Montserrat',
  }

  const isFieldVisible = isVisible()
  const isFieldRequired = required

  const { isValid } = evaluateValidity({
    isVisible: isFieldVisible,
    isRequired: isFieldRequired,
  })

  useEffect(() => {
    items.forEach(item => {
      const { key: id } = item
      const currentValue = values.ohs?.[tabID - 1]?.["checklist"]?.[id]?.selected
      const currentValueIsValid = isValid(currentValue)
      /**
       * Updates the errors state based on the validity of a field.
       * If the field is invalid, its details are stored in the errors state.
       * If the field is valid, its entry in the errors state is updated or removed.
       *
       * Errors state structure:
       * [
       *   {
       *     id: field_id,
       *     tabID: tabID,
       *     required: true | false,
       *     [other_validity_rule]: true | false,
       *   }
       * ]
       */

      // Create a copy of the current errors state
      const updatedErrors = [...errors];

      // Find the index of the existing error entry for the current field
      const errorIndex = updatedErrors.findIndex(e => e.id === id && e.tabID === tabID);

      if (errorIndex !== -1) {
        // If the error entry exists, update it with the new validity status
        updatedErrors[errorIndex] = {
          ...updatedErrors[errorIndex],
          required: !currentValueIsValid,
        };
      } else {
        // If the error entry does not exist, add a new one
        updatedErrors.push({
          id,
          tabID,
          required: !currentValueIsValid,
        });
      }

      // Update the state with the modified errors array
      if (!isEqual(errors, updatedErrors)) {
        setErrors(updatedErrors);
      }
    })
  }, [items, values, errors, tabID, setErrors, isFieldRequired, isFieldVisible, isValid])

  return isFieldVisible
    ? (
      <StyledCheckListItem
        ref={connect}
        className={cx([
          'w-full max-w-[90vw] detail-item-box',
        ])}
        margin={margin}
      >
        <FieldLabel {...textComponent} text={text} />
        {
          items.map(item => {
            const { key: id, options = [], label, helpText } = item
            const currentValue = values.ohs?.[tabID - 1]?.["checklist"]?.[id]?.selected
            const currentValueIsValid = isValid(currentValue)

            return (
              <div key={id} className="field-validation__wrapper">
                <div className="detail-item-label mb-2">
                  <FieldLabel {...textComponent} text={label} tagName="h3" required={required} />
                  {
                    helpText
                      ? <Text {...textComponent} {...helpTextProps} text={helpText} tagName="p" />
                      : null
                  }
                </div>
                <div className="detail-item detail-radio-div w-full max-w-[90vw] flex gap-4">
                  {
                    options.map(option => (
                      <div className="width-auto min-w-[27vw] lg:min-w-[160px]" key={option}>
                        <RadioButton
                          key={option}
                          className="details-radio"
                          label={option}
                          name={id}
                          onChange={() => handleOnChange(id, option, "selected", label)}
                          checked={values.ohs && values.ohs[tabID - 1]["checklist"]?.[id] !== undefined && values.ohs[tabID - 1]["checklist"]?.[id].selected === option ? true : false}
                          disabled={disabled}
                        />
                      </div>
                    ))
                  }
                </div>
                {
                  !currentValueIsValid
                    ? <div className='OHS_FORM_validation'>This field is required!</div>
                    : null
                }
                <TextArea
                  rows={6}
                  autoSize={true}
                  className={cx([
                    'w-full max-w-[90vw] work-textbox mt-4',
                  ])}
                  style={{ height: '100px' }}
                  onChange={(e) => handleOnChange(id, e.target.value, "remark", label)}
                  value={values.ohs ?
                    values.ohs[tabID - 1].checklist &&
                      values.ohs[tabID - 1]["checklist"]?.[id] !== undefined ?
                      values.ohs[tabID - 1]["checklist"]?.[id].remark
                      : ""
                    : ""}
                  placeholder="Remarks"
                />
              </div>
            )
          })
        }
      </StyledCheckListItem>
    )
    : null
};

CheckListItem.craft = {
  displayName: 'Radio Field',
  props: {
    margin: ['5', '0', '5', '0'],
    placeholder: 'Enter a value...',
    id: '',
    text: 'Field label',
    textComponent: {
      ...Text.craft.props,
    },
  },
  related: {
    toolbar: CheckListItemSettings,
  },
};
