export const getUrlFromPath = (path) => {
    try {
        return new URL(path, window.location.href)
    } catch {
        // Return the original path if failed constructing URL
        return path
    }
}

export const getParamsFromQuery = (url) => {
    try {
        // Construct params object from the path by extracting URL's search property
        return Object.fromEntries(
            new URLSearchParams(
                url?.search
            )
        );
    } catch {
        // Return empty object if failed parsing the path
        return {}
    }
}