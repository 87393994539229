import React from "react";
import { Stepper, Step } from "@progress/kendo-react-layout";
import "./AuditTrail.css";
import moment from "moment";

const CustomStep = (props) => {
  return (
    <Step {...props}>
      <span className="k-step-indicator">
        <span className="k-step-indicator-icon k-icon" />
      </span>
      <span
        className="k-step-label Audit_trail_custom_label"
        style={{
          flexDirection: "column",
          width: "88%",
          alignItems: "start",
        }}
      >
        <span
          className="k-step-text custom_label"
          style={{ marginBottom: "5px" }}
        >
          {props.description}
        </span>
        <span
          className="k-step-text custom_label1"
          style={{ marginBottom: "5px" }}
        >
          By {props.username}
        </span>
        <span
          className="k-step-text custom_label1"
          style={{ marginBottom: "50px" }}
        >
          {moment(props.created_date).format("DD MMM YYYY, HH:mm a")}
        </span>
      </span>
    </Step>
  );
};

export const AuditTrail = (props) => {
  const [value, setValue] = React.useState(0);
  console.log(props);

  return (
    <div className="Custom_stepper">
      {props.data.audit && props.data.audit.length !== 1 ? (
        <Stepper
          value={value}
          items={props.data.audit}
          item={CustomStep}
          orientation={"vertical"}
        />
      ) : (
        <div style={{ display: "flex", marginBottom: "30px" }}>
          <div>
            <div className="Audit_box"></div>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <div className="Audit_label1">
              {props.data.audit[0].description}
            </div>
            <div className="Audit_label2">{props.data.audit[0].username}</div>
            <div className="Audit_label2">
              {moment(props.data.audit[0].created_date).format(
                "DD MMM YYYY, HH:MM"
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
