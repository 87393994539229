export const LOGIN_ROUTE = "/";
export const REGISTER_ROUTE = "/register";
export const VERIFY_OTP_ROUTE = "/verify_otp";
export const FORGOT_PASSWORD_ROUTE = "/forgot_password";
export const RESET_PASSWORD_ROUTE = "/reset_password";
export const DASHBOARD_ROUTE = "/dashboard";
export const MAP_ROUTE = "/map";
export const MAP_DETAILS = "/map/details";
export const CATEGORY_ROUTE = "/category";
export const INBOX_ROUTE = "/inbox";
export const MORE_ROUTE = "/more";
export const CCTV_ROUTE = "/category/cctv";
export const CCTV_DETAILS_ROUTE = "/category/cctv/details";
export const CCTV_SPLIT_ROUTE = "/category/cctv/split";
export const TEST_ROUTE = "/video-test";
export const INCIDENT_ROUTE = "/category/incident";
export const INCIDENT_DETAILS_ROUTE = "/category/incident/details";
export const INCIDENT_DETAIL_ROUTE = "/category/incident/detail";
export const INCIDENT_FORM_ROUTE = "/category/incident/form";
export const SITREP_ROUTE = "/category/sitrep";
export const SITREP_CREATE_ROUTE = "/category/sitrep/create";
export const SITREP_EDIT_ROUTE = "/category/sitrep/edit";
export const SITREP_DETAIL_ROUTE = "/category/sitrep/detail";
export const ECM_ROUTE = "/category/ECM";
export const ECM_DETAIL_ROUTE = "/category/ECM/details";
export const ECM_HISTORICAL_RECORD_ROUTE = "/category/ECM/historicalrecord";
export const Drain_ROUTE = "/category/Drain";
export const Drain_DETAIL_ROUTE = "/category/Drain/details";
export const WEATHER_ROUTE = "/category/weather";
export const WORKDECLARATION_ROUTE = "/category/work_declaration";
export const WORKDECLARATION_CREATE_ROUTE = "/category/work_declaration/create";
export const WORKDECLARATION_EDIT_ROUTE = "/category/work_declaration/edit";
export const WORKDECLARATION_DETAIL_ROUTE = "/category/work_declaration/detail";
export const OHSINSPECTION_ROUTE = "/category/ohs_inspection";
export const OHS_INSPECTION_CREATE_ROUTE = "/category/ohs_inspection/create";
export const OHS_INSPECTION_EDIT_ROUTE = "/category/ohs_inspection/edit";
export const OHS_INSPECTION_DETAIL_ROUTE = "/category/ohs_inspection/detail";
export const INBOX_DETAIL = "/inbox/detail/";
export const INBOX_ECM_CASE_REPORT = "/inbox/ecm_case_report";
export const USER_DETAIL = "/more/profile";
export const CHANGE_PASSWORD_ROUTE = "/change_password";
export const SHARE_ROUTE = "/more/share";
export const TERMS_ROUTE = "/more/termsofuse";
export const CONTACT_ROUTE = "/more/contactus";
export const NOTIFICATION_ROUTE = "/more/notificationSetting";
