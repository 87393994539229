import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINNG_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getOrRegisterServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    return window.navigator.serviceWorker
      .getRegistration()
      .then((serviceWorker) => {
        console.log("Check firebase serviceworker: ", serviceWorker);
        if (serviceWorker) return serviceWorker;
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then(
    (serviceWorkerRegistration) =>
      getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
        serviceWorkerRegistration,
      }),
    onMessage(messaging, (payload) => {
      console.log("Received foreground message: ", payload);
      const notificationTitle = payload.data.title;
      const notificationOptions = {
        body: payload.data.body,
        data: { url: payload.data.url },
        icon: payload.data.icon,
      };

      navigator.serviceWorker.ready.then(function (registration) {
        console.log(registration);
        registration.showNotification(notificationTitle, notificationOptions);
      });
    })
  );

export const deleteFirebaseToken = () => {
  try {
    deleteToken(messaging).then(console.log("success"));
  } catch (e) {
    console.log(e);
  }
};

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
