import { useEditor } from '@craftjs/core';
import cx from 'classnames';
import React from 'react';
import { Sidebar } from './Sidebar';

export const Viewport = ({
  touched,
  children,
}) => {
  const {
    enabled,
    connectors,
  } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  return (
    <div className={`viewport form-designer__viewport ${touched ? 'form-designer__viewport--touched' : ''}`}>
      <div
        className={cx(['flex h-[calc(100%_-_275px)] bg-white overflow-hidden custom_css flex-row fixed'])}
      >
        <div className={`page-container flex flex-1 h-full flex-col`}>
          <div
            className={cx([
              'craftjs-renderer flex-1 h-full w-full transition pb-8 overflow-auto',
              {
                'bg-renderer-gray': enabled,
              },
            ])}
            ref={(ref) => connectors.select(connectors.hover(ref, null), null)}
          >
            <div className="relative flex-col flex items-center pt-8">
              {children}
            </div>
          </div>
        </div>
        <Sidebar />
      </div>
    </div>
  );
};
