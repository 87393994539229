import React from "react";
// kendo
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
// icon
import CrossIcon from "../assets/images/cctv/cross_gray_icon.png";
// css
import "./confirmMsg.css";

const ConfirmMsg = ({
  onCloseDialog,
  msgTitle,
  okBtnMsg,
  okBtnClicked,
  cancelBtnMsg,
  cancelBtnClicked,
  msgContent,
}) => {
  return (
    <Dialog style={{ zIndex: "11000" }}>
      <div className="confirm-msg-dialog-wrapper">
        <div
          style={{
            position: "absolute",
            top: "15px",
            right: "13px",
            cursor: "pointer",
          }}
          onClick={onCloseDialog}
        >
          <img src={CrossIcon} />
        </div>
        <div className="confirm-msg-title">{msgTitle || "Are you sure?"}</div>
        {msgContent && <div className="confirm-msg-content">{msgContent}</div>}
        <div className="confirm-msg-buttons">
          {cancelBtnClicked && (
            <Button
              className="confirm-msg-btn cancel"
              onClick={cancelBtnClicked}
            >
              {cancelBtnMsg || "Cancel"}
            </Button>
          )}
          <Button
            className={
              cancelBtnClicked ? "confirm-msg-btn ok" : "confirm-msg-btn-1 ok"
            }
            onClick={okBtnClicked}
          >
            {okBtnMsg || "OK"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmMsg;
