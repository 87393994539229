import { TextBox } from '@progress/kendo-react-inputs';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { ChromePicker } from 'react-color';

export const ToolbarTextInput = ({
  onChange,
  value,
  prefix,
  label,
  type,
  ...props
}) => {
  const [internalValue, setInternalValue] = useState(value);
  const [active, setActive] = useState(false);

  useEffect(() => {
    let val = value;
    if (type === 'color' || type === 'bg')
      val = `rgba(${Object.values(value)})`;
    setInternalValue(val);
  }, [value, type]);

  return (
    <div
      style={{ width: '100%', position: 'relative' }}
      onClick={() => {
        setActive(true);
      }}
    >
      {(type === 'color' || type === 'bg') && active ? (
        <div
          className="absolute"
          style={{
            zIndex: 99999,
            top: 'calc(100% + 10px)',
            left: '-5%',
          }}
        >
          <div
            className="fixed top-0 left-0 w-full h-full cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setActive(false);
            }}
          ></div>
          <ChromePicker
            color={value}
            onChange={(color) => {
              onChange(color.rgb);
            }}
          />
        </div>
      ) : null}
      <TextBox
        label={label}
        style={{ margin: 0, width: '100%' }}
        value={internalValue || ''}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onChange((e.target).value);
          }
        }}
        onChange={(e) => {
          setInternalValue(e.target.value);
        }}
        prefix={
          ['color', 'bg'].includes(type) ? () => (
            <div
              className="flex items-center ml-2"
            >
              <div
                className="w-2 h-2 inline-block rounded-full relative"
                style={{
                  background: internalValue,
                }}
              />
            </div>
          ) : undefined
        }
        {...props}
      />
    </div>
  );
};
