export function isEqual(x, y) {
    // If both are strictly equal, return true
    if (x === y) return true;

    // If either is not an object (or is null), return false
    if (typeof x !== 'object' || typeof y !== 'object' || x === null || y === null) {
        return false;
    }

    // If either x or y is not an array (or not an object), return false
    if (Array.isArray(x) !== Array.isArray(y)) {
        return false;
    }

    // If they are arrays, check length and contents
    if (Array.isArray(x)) {
        if (x.length !== y.length) return false;
        return x.every((item, index) => isEqual(item, y[index]));
    }

    // If they are objects, check keys and values
    const xKeys = Object.keys(x);
    const yKeys = Object.keys(y);

    if (xKeys.length !== yKeys.length) return false;

    return xKeys.every(key => isEqual(x[key], y[key]));
}
