import React, { useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import CrossIcon from "../../../assets/images/Icon/Cross_black.svg";
import { useSelector } from "react-redux";
import FullscreenLoader from "../../../components/fullScreenLoader";
import { DatePicker } from "@progress/kendo-react-dateinputs";

const statusData = [
  {
    id: "open",
    name: "Open",
  },
  {
    id: "draft",
    name: "Draft",
  },
  {
    id: "closed",
    name: "Closed",
  },
  {
    name: "For Rectification",
    id: "for_rectification",
  },
  {
    id: "for_approval",
    name: "For Approval",
  },
  {
    id: "not_valid",
    name: "Not Valid",
  },
];

export const OHS_Filter = (props) => {
  const { general_config_data } = useSelector((state) => state.generalConfig);
  let current_user = JSON.parse(localStorage.getItem("current_user"));
  const [tempFilter, setTempFilter] = useState({
    company_name: [],
    package: [],
    label: [],
  });
  const [isTempFilter, setIsTempFilter] = useState({
    company_name: false,
    package: false,
    label: false,
  });
  const [loading, setLoading] = useState({
    company_name: false,
    package: false,
    label: false,
  });

  const filtering = (data, target, filterValue) => {
    return data.filter((i) =>
      target === "package"
        ? i.replace(/ /g, "").toLowerCase().includes(filterValue.toLowerCase())
        : i[target]
            .replace(/ /g, "")
            .toLowerCase()
            .includes(filterValue.toLowerCase())
    );
  };

  const filterData = (filter, data) => {
    const targetedField = filter.field === undefined ? "package" : filter.field;
    return filtering(data, targetedField, filter.value);
  };
  const timeout = React.useRef();

  const handleFilterData = (event, data) => {
    const targetedField =
      event.filter.field === undefined ? "package" : event.filter.field;
    console.log(event.filter);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      console.log(filterData(event.filter, data));
      setTempFilter({
        ...tempFilter,
        [targetedField]: filterData(event.filter, data),
      });
      // setLoading(false);
      setLoading({
        ...loading,
        [targetedField]: false,
      });
      setIsTempFilter({
        ...isTempFilter,
        [targetedField]: true,
      });
    }, 500);
    // setLoading(true);
    setLoading({
      ...loading,
      [targetedField]: true,
    });
  };

  console.log(props.filterError);

  return (
    <Dialog>
      {props.filterLoader && (
        <div className="OHS_Filter_loader">
          <FullscreenLoader />
        </div>
      )}
      <div className="OHS_Filter_Main_box">
        <div style={{ position: "sticky", top: 0, zIndex: 3 }}>
          <div style={{ position: "relative" }}>
            <div
              className="OHS_Filter_cross_button"
              onClick={() => props.setShowFilter(false)}
            >
              <img src={CrossIcon} />
            </div>
          </div>
        </div>
        <div
          className="OHS_Column_main_box"
          style={{ margin: "50px 20px 20px" }}
        >
          Filter
        </div>
        <div className="OHS_Filter_sec_box">
          <div>
            <div style={{ display: "flex", gap: "20px" }}>
              <div
                className="OHS_Filter_child"
                style={{ justifyContent: "space-between" }}
              >
                <div className="OHS_Filter_child_title">
                  Date of Observation (From)
                </div>
                <DatePicker
                  // defaultValue={startDate}
                  defaultShow={false}
                  format={"dd MMM yyyy"}
                  value={props.filterValue["from_date"]}
                  onChange={(e) =>
                    props.onChangeFilter(e.target.value, "from_date")
                  }
                  className="OHS-filter-date-input"
                />
              </div>
              <div
                className="OHS_Filter_child"
                style={{ justifyContent: "space-between" }}
              >
                <div className="OHS_Filter_child_title">
                  Date of Observation (To)
                </div>
                <DatePicker
                  // defaultValue={startDate}
                  defaultShow={false}
                  format={"dd MMM yyyy"}
                  value={props.filterValue["to_date"]}
                  onChange={(e) =>
                    props.onChangeFilter(e.target.value, "to_date")
                  }
                  className="OHS-filter-date-input"
                />
              </div>
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              {props.filterError.isError && (
                <div className="OHS_error_text">
                  {props.filterError.startDate}
                </div>
              )}
              {props.filterError.isError && (
                <div className="OHS_error_text">
                  {props.filterError.endDate}
                </div>
              )}
            </div>
          </div>
          <div className="OHS_Filter_child">
            <div className="OHS_Filter_child_title">User Group</div>
            <DropDownList
              data={["Project Team", "Safety Oversight Team"]}
              className="selector--dropdown"
              onChange={(e) =>
                props.onChangeFilter(e.target.value, "user_group")
              }
              value={props.filterValue["user_group"]}
              // disabled={disabled}
            />
          </div>
          <div className="OHS_Filter_child">
            <div className="OHS_Filter_child_title">Source of observation</div>
            <DropDownList
              data={
                props.filterData
                  ? props.filterData["source_of_observation"]
                  : []
              }
              className="selector--dropdown"
              onChange={(e) =>
                props.onChangeFilter(e.target.value, "source_of_observation")
              }
              value={props.filterValue["source_of_observation"]}
              // disabled={disabled}
            />
          </div>
          <div className="OHS_Filter_child">
            <div className="OHS_Filter_child_title">Package</div>
            <MultiSelect
              onChange={(e) => props.onChangeFilter(e.target.value, "package")}
              style={{
                width: "100%",
                marginTop: "10px",
              }}
              data={
                isTempFilter.package
                  ? tempFilter.package
                  : ["All", ...current_user["package"]]
              }
              // data={["all", ...Package]}
              value={props.filterValue["package"]}
              filterable={true}
              loading={loading.package}
              onFilterChange={(e) =>
                handleFilterData(e, ["All", ...current_user["package"]])
              }
            />
          </div>
          <div className="OHS_Filter_child">
            <div className="OHS_Filter_child_title">Work Zone</div>
            <DropDownList
              data={props.filterData ? props.filterData["work_zone"] : []}
              className="selector--dropdown"
              onChange={(e) =>
                props.onChangeFilter(e.target.value, "work_zone")
              }
              value={props.filterValue["work_zone"]}
              // disabled={disabled}
            />
          </div>
          <div className="OHS_Filter_child">
            <div className="OHS_Filter_child_title">Type of work Activity</div>
            <DropDownList
              data={
                props.filterData
                  ? props.filterData["type_of_work_activity"]
                  : []
              }
              className="selector--dropdown"
              onChange={(e) =>
                props.onChangeFilter(e.target.value, "type_of_work_activity")
              }
              value={props.filterValue["type_of_work_activity"]}
              // disabled={disabled}
            />
          </div>
          <div className="OHS_Filter_child">
            <div className="OHS_Filter_child_title">
              Significant Level of Observation
            </div>
            <DropDownList
              data={
                props.filterData
                  ? props.filterData["significant_level_of_observation"]
                  : []
              }
              className="selector--dropdown"
              onChange={(e) =>
                props.onChangeFilter(
                  e.target.value,
                  "significant_level_of_observation"
                )
              }
              value={props.filterValue["significant_level_of_observation"]}
              // disabled={disabled}
            />
          </div>
          <div className="OHS_Filter_child">
            <div className="OHS_Filter_child_title">Type of risk</div>
            <DropDownList
              data={props.filterData ? props.filterData["type_of_risk"] : []}
              className="selector--dropdown"
              onChange={(e) =>
                props.onChangeFilter(e.target.value, "type_of_risk")
              }
              value={props.filterValue["type_of_risk"]}
              // disabled={disabled}
            />
          </div>
          <div className="OHS_Filter_child">
            <div className="OHS_Filter_child_title">
              Company Carrying Out Work Activity
            </div>
            <DropDownList
              textField="company_name"
              dataItemKey="company_name"
              data={
                props.filterData ? props.filterData["company_carrying"] : []
              }
              className="selector--dropdown"
              onChange={(e) =>
                props.onChangeFilter(
                  e.target.value.company_name,
                  "company_carrying"
                )
              }
              value={
                props.filterData
                  ? props.filterData["company_carrying"].find(
                      (i) => i.name === props.filterValue["company_carrying"]
                    )
                  : ""
              }
              // disabled={disabled}
            />
          </div>
          <div className="OHS_Filter_child">
            <div className="OHS_Filter_child_title">Hazards</div>
            <DropDownList
              data={props.filterData ? props.filterData["hazards"] : []}
              className="selector--dropdown"
              onChange={(e) => props.onChangeFilter(e.target.value, "hazards")}
              value={props.filterValue["hazards"]}
              // disabled={disabled}
            />
          </div>
          <div className="OHS_Filter_child">
            <div className="OHS_Filter_child_title">Potential Consequences</div>
            <DropDownList
              data={
                props.filterData
                  ? props.filterData["potential_consequences"]
                  : []
              }
              className="selector--dropdown"
              onChange={(e) =>
                props.onChangeFilter(e.target.value, "potential_consequences")
              }
              value={props.filterValue["potential_consequences"]}
              // disabled={disabled}
            />
          </div>

          {props.status ? (
            <div className="OHS_Filter_child">
              <div className="OHS_Filter_child_title">Status</div>
              <DropDownList
                data={[
                  {
                    id: "open",
                    name: "Open",
                  },
                  {
                    id: "draft",
                    name: "Draft",
                  },
                  {
                    id: "closed",
                    name: "Closed",
                  },
                  {
                    name: "For Rectification",
                    id: "for_rectification",
                  },
                  {
                    id: "for_approval",
                    name: "For Approval",
                  },
                  {
                    id: "not_valid",
                    name: "Not Valid",
                  },
                ]}
                dataItemKey="id"
                textField="name"
                className="selector--dropdown"
                onChange={(e) =>
                  props.onChangeFilter(e.target.value.id, "status")
                }
                // disabled={disabled}
                value={statusData.find(
                  (i) => i.id === props.filterValue["status"]
                )}
              />
            </div>
          ) : null}
        </div>
        <div className="OHS_Filter_footer_main_box">
          <div
            className="OHS_Filter_footer_clear"
            onClick={() => props.filterClear(false)}
          >
            Clear
          </div>
          <div className="OHS_Filter_footer_apply" onClick={props.filterApply}>
            Apply
          </div>
        </div>
      </div>
    </Dialog>
  );
};
