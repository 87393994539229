const IdentifierHelper = ({ data, workFlow, approval_detail }) => {
  console.log("IndentifierHelper", data, workFlow);
  const workFlowDecider = () => {
    let workFlow_decider_detail = {};
    if (data.id && workFlow.length > 0) {
      workFlow.map((i, id) => {
        i.flow_decider.flow_checker.map((j) => {
          console.log(j, id);
          let index = 0;
          j.map((k) => {
            if (
              data[k.field_name] &&
              k.field_value.find((l) => l === data[k.field_name])
            ) {
              index += 1;
            }
            if (index === 2 && k.decided_flow) {
              console.log(
                k.decided_flow,
                i.flow_decider.flow_eligible[k.decided_flow],
                id
              );
              workFlow_decider_detail = {
                flow: k.decided_flow,
                flow_eligible: i.flow_decider.flow_eligible[k.decided_flow],
                flow_id: id,
                flow_stages: i.stages,
              };
            }
          });
        });
      });
    }
    return workFlow_decider_detail;
  };

  const stageDecider = () => {
    const current_user = JSON.parse(localStorage.getItem("current_user"));
    let decidedWorkFlow = workFlowDecider();
    let currentStage = {
      is_current_user_can_edit: false,
    };
    Object.keys(decidedWorkFlow.flow_stages).map((key, index) => {
      const value = decidedWorkFlow.flow_stages[key];
      if (value.status.find((i) => i === data.status)) {
        console.log(
          "current_stage",
          key,
          index,
          "next_stage",
          Object.keys(decidedWorkFlow.flow_stages)[index + 1]
        );
        if (Object.keys(decidedWorkFlow.flow_stages)[index + 1] === undefined) {
          return currentStage;
        }
        if (value.editable.role_checker && value.editable.permission_checker) {
          let role = current_user.role.split(",");
          let permission = current_user.permissions;
          let role_checker = value.editable.editable_role.find((j) =>
            role.find((l) => l === j)
          );
          let permission_checker = value.editable.editable_permission.find(
            (j) => permission.find((l) => l === j)
          );
          let stage_checker =
            approval_detail.length > 0
              ? key ===
                approval_detail[approval_detail.length - 1].workflow_config
                  .next_stage
              : key;
          if (value.editable.editable_role.length > 1) {
            let find = value.editable.editable_role.find((l) =>
              decidedWorkFlow.flow_eligible.approve_eligible.find(
                (n) => n === l
              )
            );
            console.log(
              role.find((l) => l === find),
              find,
              role
            );
            permission_checker = role.find((l) => l === find);
          }
          if (!value.editable.editable_status.find((l) => l === data.status)) {
            console.log(
              "not find",
              value.editable.editable_status.find((l) => l === data.status),
              data.status,
              value.editable.editable_status,
              stage_checker,
              decidedWorkFlow
            );
            return;
          }
          console.log("field check", value);
          if (
            value.editable.editable_fields_without_permission_role &&
            value.editable.editable_fields_without_permission_role.find((j) =>
              role.find((l) => l === j)
            )
          ) {
            console.log(
              "debugger",
              role_checker,
              permission_checker,
              value,
              decidedWorkFlow.flow_eligible.approve_eligible.find((i) =>
                value.editable.editable_role.find((j) => j === i)
              )
            );
            currentStage = {
              is_current_user_can_edit: false,
              editable: {
                editable_fields_without_permission:
                  value.editable.editable_fields_without_permission,
              },
              outstanding_by:
                decidedWorkFlow.flow_eligible.approve_eligible.find((i) =>
                  value.editable.editable_role.find((j) => j === i)
                ),
            };
          }
          if (role_checker && permission_checker && stage_checker) {
            const next_stage = Object.keys(decidedWorkFlow.flow_stages)[
              index + 1
            ];
            const outstanding_by =
              decidedWorkFlow.flow_eligible.approve_eligible.find((i) =>
                decidedWorkFlow.flow_stages[
                  next_stage
                ].editable.editable_role.find((j) => j === i)
              );
            return role_checker && permission_checker
              ? (currentStage = {
                  ...value,
                  current_stage: key,
                  next_stage: next_stage,
                  is_current_user_can_edit: true,
                  flow_id: decidedWorkFlow.flow_id,
                  outstanding_by:
                    outstanding_by === undefined ? "-" : outstanding_by,
                  key,
                })
              : (currentStage = {
                  is_current_user_can_edit: false,
                });
          }
          return currentStage;
        }
        if (
          !value.editable.role_checker &&
          !value.editable.permission_checker
        ) {
          const next_stage = Object.keys(decidedWorkFlow.flow_stages)[
            index + 1
          ];
          const outstanding_by =
            decidedWorkFlow.flow_eligible.approve_eligible.find((i) =>
              decidedWorkFlow.flow_stages[
                next_stage
              ].editable.editable_role.find((j) => j === i)
            );
          return current_user.sub === data.created_uid
            ? (currentStage = {
                ...value,
                current_stage: key,
                next_stage: Object.keys(decidedWorkFlow.flow_stages)[index + 1],
                is_current_user_can_edit: true,
                flow_id: decidedWorkFlow.flow_id,
                outstanding_by:
                  outstanding_by === undefined ? "-" : outstanding_by,
              })
            : (currentStage = {
                is_current_user_can_edit: false,
              });
        }
      }
    });
    console.log(currentStage);
    return currentStage;
  };

  return stageDecider();
};

export default IdentifierHelper;

export const GetOutstandingBy = ({ data, workFlow, nextStage }) => {
  const workFlowDecider = () => {
    let workFlow_decider_detail = {};
    if (data.id && workFlow.length > 0) {
      workFlow.map((i, id) => {
        i.flow_decider.flow_checker.map((j) => {
          console.log(j, id);
          let index = 0;
          j.map((k) => {
            if (
              data[k.field_name] &&
              k.field_value.find((l) => l === data[k.field_name])
            ) {
              index += 1;
            }
            if (index === 2 && k.decided_flow) {
              console.log(
                k.decided_flow,
                i.flow_decider.flow_eligible[k.decided_flow],
                id
              );
              workFlow_decider_detail = {
                flow: k.decided_flow,
                flow_eligible: i.flow_decider.flow_eligible[k.decided_flow],
                flow_id: id,
                flow_stages: i.stages,
              };
            }
          });
        });
      });
    }
    return workFlow_decider_detail;
  };
  const getOutStandingRole = () => {
    let decidedWorkFlow = workFlowDecider();
    const outstanding_by = decidedWorkFlow.flow_eligible.approve_eligible.find(
      (i) =>
        decidedWorkFlow.flow_stages[nextStage].editable.editable_role.find(
          (j) => j === i
        )
    );
    return outstanding_by;
  };

  return getOutStandingRole();
};
