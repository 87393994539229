// react
import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
// kendo
import { Button } from "@progress/kendo-react-buttons";
// css
import "./mobileHeaderFiltered.css";
//icon
import CreateIcon from "../../assets/images/OHS/CreateIcon.svg";
const MobileHeaderFiltered = (props) => {
  const { route, icons, shouldHideBack, button, buttonLink } = props;
  const navigate = useNavigate();
  const backClicked = () => {
    if (route) {
      navigate(route);
    } else {
      window.history.go(-1);
      return false;
    }
  };
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 991 });

  return (
    <div className={props.Class ? `header-box ${props.Class}` : "header-box"}>
      {props.CustomClick ? (
        <div>
          <Button
            className="back-btn"
            onClick={() => {
              props.CustomClick();
            }}
          >
            <span
              className="k-icon k-i-arrow-chevron-left"
              onClick={() => {
                props.CustomClick();
              }}
            ></span>
          </Button>
        </div>
      ) : (
        <>
          {shouldHideBack && !isMobile && !isTablet ? (
            <></>
          ) : (
            <div>
              <Button
                className="back-btn"
                onClick={() => {
                  backClicked();
                }}
              >
                <span className="k-icon k-i-arrow-chevron-left"></span>
              </Button>
            </div>
          )}
        </>
      )}
      <div className={`header-text ${icons.length === 0 && "flexin"}`}>
        {props.title}
      </div>
      <div className="header_button_main_box">
        {button && (
          <div className="header_Button_box" onClick={buttonLink}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={CreateIcon} style={{ width: "14px", height: "14px" }} />
            </div>
            <div>{button}</div>
          </div>
        )}
        {icons.length > 0 && (
          <div className="header-logo-box">
            {icons
              .filter((i) => i.show === true)
              .map((icon, i) => {
                if (icon.logo) {
                  return (
                    <img
                      key={i}
                      src={icon.logo}
                      onClick={() => {
                        icon.handleClicked(icon);
                      }}
                    />
                  );
                } else {
                  return (
                    <span
                      className="clickable-text-button"
                      key={i}
                      onClick={() => {
                        icon.handleClicked(icon);
                      }}
                    >
                      {icon.text}
                    </span>
                  );
                }
              })}
          </div>
        )}
      </div>
    </div>
  );
};
export default MobileHeaderFiltered;
