import React, { useRef, useEffect, useState, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import mapboxgl from "mapbox-gl";
import "./map.css";
import { useNavigate } from "react-router";
import {
  CCTV_DETAILS_ROUTE,
  INCIDENT_DETAILS_ROUTE,
  WORKDECLARATION_ROUTE,
} from "../../routes";
import {
  AddMarker,
  DeleteItems,
  DrawPolygon,
  MultiPinDisabler,
  MultiPinPageLoadDetail,
  MultiPinPageOnLoad,
  Package_plan_,
  RemoveLabel,
  RemoveMarkermulti,
  RoadName,
  Satilite_plan,
  SetHightLight,
  setMarkerOnMap,
  Site_Utilisation_plan,
  SitrepMarker,
} from "./map_service";
import { SVY21 } from "./SVY21";
import Pin from "../../assets/images/Map/map_multipin_icon.svg";
import MarkerIcon from "../../assets/images/Map/annotationIcon/Marker.svg";

const Map = React.forwardRef(({ actionButtons, ...props }, ref) => {
  // mapboxgl.accessToken =
  //   "pk.eyJ1Ijoic2l0aHV0YSIsImEiOiJjbGNvaGs4eDIwOTliM3FtanUya3JpcXEyIn0.rLjo8LfqJ1TOUNgYzDgNdg";
  mapboxgl.accessToken =
    "pk.eyJ1IjoiY2FnY2VyZWFsIiwiYSI6ImNsczJxcjdzMDBxMjAybHJ3ODBsa2J5N3MifQ.lqdu9trNaxTQ7imgADlgWA";
  const mapContainer = useRef(null);
  const map = useRef(null);
  const navigate = useNavigate();
  const [lng, setLng] = useState(104.00697448558248);
  const [lat, setLat] = useState(1.3427399895880683);
  const [zoom, setZoom] = useState(13);
  const [Maps, setMaps] = useState();
  const [activeBg, setActiveBg] = useState(false);

  const { road_name_data: roadName } = useSelector(
    (state) => state.generalConfig
  );

  const MenuItem = (e) => {
    if (e.target.id !== "menuOnClick") {
      navigate(`${CCTV_DETAILS_ROUTE}?camera_no=${e.target.id}`);
    }
  };

  const FavMenuItem = (e) => {
    localStorage.setItem(
      "FavIconClicked",
      e.target.getAttribute("data-type") +
        "_" +
        e.target.getAttribute("data-id")
    );
  };

  const UnFavMenuItem = (e) => {
    localStorage.setItem(
      "FavIconClicked",
      e.target.getAttribute("data-type") +
        "_" +
        e.target.getAttribute("data-id")
    );
  };

  const IncidentClicked = (e) => {
    if (e.target.id !== "IncidentOnClick") {
      navigate(`${INCIDENT_DETAILS_ROUTE}?id=${e.target.id}`);
    }
  };
  const WorkClicked = (e) => {
    if (e.target.id !== "WorkOnClick") {
      navigate(`${WORKDECLARATION_ROUTE}?id=${e.target.id}`);
    }
  };
  const MenuItem2 = () => {
    console.log("Click2");
  };

  useEffect(() => {
    console.log(props);
    var cv = new SVY21();
    localStorage.removeItem("Total_length");
    localStorage.removeItem("Marker_location");
    if (map.current) return; // initialize map only once
    const bound = [
      [103.97783496724884, 1.312956028536476],
      [104.05883869088393, 1.379913509790697],
    ];
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-streets-v12",
      center: [
        props.centerLng ? props.centerLng : lng,
        props.centerLat ? props.centerLat : lat,
      ],
      zoom: zoom,
      minZoom: 13,
      maxZoom: 40,
      dragRotate: false,
      bearing: -67.4,
      maxBounds: !props.sitrep && bound,
      interactive: props.disableInteractive ? false : true,
    });
    map.current.doubleClickZoom.disable();
    RemoveLabel(map.current);

    setMaps(map.current);
    if (props.addFullScreen) {
      map.current.addControl(new mapboxgl.FullscreenControl());
    }
    if (!props.addFullScreen) {
      const el = document.querySelector("div.mapboxgl-ctrl-top-right");
      el.classList.remove("full_map");
    }
    if (props.geolocation === true) {
      map.current.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          // When active the map will receive updates to the device's location as it changes.
          trackUserLocation: true,
          // Draw an arrow next to the location dot to indicate which direction the device is heading.
          showUserHeading: true,
        })
      );
    }
    if (props.marker === true) {
      const marker = new mapboxgl.Marker({
        draggable: props.disableMarkerDrag ? false : true,
      })
        .setLngLat([
          props.markerLng ? props.markerLng : lng,
          props.markerLat ? props.markerLat : lat,
        ])
        .addTo(map.current);
      function onDragEnd() {
        const lngLat = marker.getLngLat();
        console.log(lngLat.lng, lngLat.lat);
        localStorage.setItem("incident_point", lngLat.lng + "," + lngLat.lat);
        props.onEditMap();
      }
      marker.on("dragend", onDragEnd);
    }

    if (props.marker_for_sitrep === true) {
      const marker = new mapboxgl.Marker({
        draggable: props.disableMarkerDrag ? false : true,
      })
        .setLngLat([
          props.markerLng ? props.markerLng : lng,
          props.markerLat ? props.markerLat : lat,
        ])
        .addTo(map.current);
      function onDragEnd() {
        const lngLat = marker.getLngLat();
        props.handlePinChange(lngLat.lat, lngLat.lng);
      }
      marker.on("dragend", onDragEnd);
    }

    if (props.EditMode === true) {
      // MultiPinPageOnLoad(map.current, props.EditMultiPinData);
      if (props.detailView === true) {
        console.log(props.detailMapPoints);
        map.current.on("load", () => {
          MultiPinPageLoadDetail(map.current, props.detailMapPoints);
        });
      } else {
        console.log("doing");
        map.current.on("load", () => {
          props.AddMarkerOnPageLoad();
        });
      }
    }

    // if (props.mapFirstLoadHelp) {
    //   props.MapFirstLoadCall("useEffect");
    // }
    if (props.disableScrollZoom && map.scrollZoom) {
      map.scrollZoom.disable();
    }

    if (props.sitrep === true) {
      SitrepMarker(props.incidentData, props.SitrepData, map.current);
    }

    if (props.showUtilisation === true) {
      const filter = JSON.parse(localStorage.getItem("Map_layer"))
        ? JSON.parse(localStorage.getItem("Map_layer"))
        : [];
      // Site_Utilisation_plan(
      //   map.current,
      //   [{ name: "Utilisation_plan", list: [] }],
      //   true
      // );
      if (props.wdc_case === true) {
        // Site_Utilisation_plan(
        //   map.current,
        //   [{ name: "Utilisation_plan", list: [] }],
        //   true
        // );
        Site_Utilisation_plan(
          map.current,
          filter,
          true,
          props.mapLayerImage,
          true
        );
      } else if (props.const_image === true) {
        Satilite_plan(map.current, [{ name: "Satilite_plan", list: [] }], true);
      } else {
        if (filter.find((d) => d.name === "Satilite_plan")) {
          Site_Utilisation_plan(
            map.current,
            filter,
            true,
            props.mapLayerImage,
            false
          );
        }
      }
    }

    // if (props.MultiPinSet === true) {
    //   map.current.getCanvas().style.cursor = `url(${MarkerIcon}) 19 50, auto`;
    //   map.current.on("click", props.handleMultiPinChange);
    //   // map.current.on("click", (e) => console.log(e));
    // }

    if (props.zoom === true) {
      map.current.addControl(new mapboxgl.NavigationControl(), "bottom-left");
    }

    if (props.pin === true && !props.filter) {
      const filter = JSON.parse(localStorage.getItem("Map_layer"))
        ? JSON.parse(localStorage.getItem("Map_layer"))
        : [];
      if (filter) {
        setMarkerOnMap(props.map_data, map.current, filter);
      }
    }

    if (props.annotation === true) {
      console.log(props.MarkerData);
      // AddMarker(map.current, props.MarkerData);
      const filter = JSON.parse(localStorage.getItem("Map_layer"))
        ? JSON.parse(localStorage.getItem("Map_layer"))
        : [];
      if (filter) {
        setMarkerOnMap(props.map_data, map.current, filter);
      }
      DrawPolygon(map.current, "", props.AnnotationData);
    }

    if (props.filter) {
      setMarkerOnMap(props.map_data, map.current, props.filter);
    }
    if (props.package) {
      const filter = JSON.parse(localStorage.getItem("Map_layer"))
        ? JSON.parse(localStorage.getItem("Map_layer"))
        : [];
      if (filter) {
        Package_plan_(map.current, filter, true);
        Satilite_plan(map.current, filter, true);
        RoadName(map.current, filter, true, roadName);
        // Site_Utilisation_plan(map.current, filter, true);
      }
    }
  });

  setInterval(() => {
    const id = document.getElementById("menuOnClick");
    if (id) {
      id.onclick = MenuItem;
    }
  }, 500);

  setInterval(() => {
    const elements = document.getElementsByClassName("favOnClick");
    if (elements) {
      Array.from(elements).forEach((element) => {
        element.onclick = FavMenuItem;
      });
    }
  }, 500);

  setInterval(() => {
    const elements = document.getElementsByClassName("unFavOnClick");
    if (elements) {
      Array.from(elements).forEach((element) => {
        element.onclick = UnFavMenuItem;
      });
    }
  }, 500);

  setInterval(() => {
    const id = document.getElementById("IncidentOnClick");
    if (id) {
      id.onclick = IncidentClicked;
    }
  }, 500);
  setInterval(() => {
    const id = document.getElementById("WorkOnClick");
    if (id) {
      id.onclick = WorkClicked;
    }
  }, 500);

  setInterval(() => {
    const idRight = document.querySelector("#menuOnClickRight");
    if (idRight) {
      idRight.addEventListener("click", MenuItem2);
    }
  }, 500);
  useImperativeHandle(ref, () => ({
    mapLayerFilterFunction(boolean) {
      console.log("checkboolean", boolean);
      // map.current.on("load", function () {
      if (boolean === "useEffect") {
        // map.current.on("load", function () {
        console.log("working");
        const filter = JSON.parse(localStorage.getItem("Map_layer"))
          ? JSON.parse(localStorage.getItem("Map_layer"))
          : [];
        if (filter) {
          const current_package = JSON.parse(
            localStorage.getItem("current_user")
          ).package;
          let addedFilter = [...filter];
          if (!filter.find((da) => da.name === "Incident_case")) {
            addedFilter.push({
              name: "Incident_case",
              list: current_package,
            });
          }
          if (!filter.find((da) => da.name === "Work_declaration")) {
            addedFilter.push({
              name: "Work_declaration",
              list: current_package,
            });
          }
          if (!filter.find((da) => da.name === "Road_name")) {
            addedFilter.push({
              name: "Road_name",
              list: [],
            });
          }
          setMarkerOnMap(props.map_data, map.current, addedFilter);
          RoadName(map.current, addedFilter, false, roadName);
          Site_Utilisation_plan(
            map.current,
            filter,
            false,
            props.mapLayerImage,
            false
          );
        }
        // });
      } else {
        const filter = JSON.parse(localStorage.getItem("Map_layer"))
          ? JSON.parse(localStorage.getItem("Map_layer"))
          : [];
        if (filter) {
          setMarkerOnMap(props.map_data, Maps, filter);
          Package_plan_(map.current, filter, false);
          Satilite_plan(map.current, filter, false);
          RoadName(map.current, filter, false, roadName);
          Site_Utilisation_plan(
            map.current,
            filter,
            false,
            props.mapLayerImage,
            false
          );
        }
      }
      // });
    },
    addMutiPin(data) {
      return AddMarker(Maps, data, props.handleMultiPinChange, setActiveBg);
    },
    removeMultiPin(id, data) {
      return RemoveMarkermulti(id, data);
    },
    IncidentHandle(data, data1) {
      // const data_split = data1.split(",");
      // Maps.setCenter(data_split);
      // Maps.setZoom(15);
      const filter = JSON.parse(localStorage.getItem("Map_layer"))
        ? JSON.parse(localStorage.getItem("Map_layer"))
        : [];
      setMarkerOnMap(props.map_data, Maps, filter, data);
    },
    viewReload(data, data1) {
      Maps.setCenter(data);
      SetHightLight(Maps, data1, props.AnnotationData);
      Maps.setZoom(13);
    },
    Delete(data, annotation, marker) {
      DeleteItems(Maps, data, annotation, marker);
    },
    SetPin(data, filter) {
      console.log(filter);
      Maps.setCenter(filter[0].lat_long);
      setMarkerOnMap(data, map.current, filter);
    },
  }));
  return (
    <>
      <div>
        <div ref={mapContainer} className="map-container" />
        {props.MultiPinSet === true && (
          <div style={{ position: "relative" }}>
            <div className="anontation_main_box">
              <div
                className="anontation_item1"
                style={{ background: activeBg && "#EB7930" }}
                onClick={() => {
                  setActiveBg(true);
                  MultiPinDisabler(Maps, props.handleMultiPinChange);
                }}
              >
                <img src={Pin} className="anontation_item1_img" />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
});

export default Map;
