import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect, useState } from 'react';

const ContentModal = (props) => {
    const { children, renderContent } = props
    const [visible, setVisible] = useState(false)
    const toggleDialog = () => {
        setVisible(!visible);
    };

    useEffect(() => {
        if (visible) {
            const overlay = document.querySelector('.k-overlay')
            console.log('ov', overlay)
            if (overlay) {
                overlay.onclick = () => {
                    setVisible(false)
                }
            }
        }
    }, [visible])

    return (
        <div className="cursor-pointer">
            <div onClick={toggleDialog}>
                {children}
            </div>
            {
                visible
                    ? (
                        <Dialog onClose={toggleDialog}>
                            {
                                typeof renderContent === 'function'
                                    ? renderContent()
                                    : null
                            }
                        </Dialog>
                    )
                    : null
            }
        </div>
    );
};
export default ContentModal;