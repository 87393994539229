import React, { useState, useEffect, Suspense, lazy } from "react";
import { Route, Routes } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";

// routes
import {
  DASHBOARD_ROUTE,
  MAP_ROUTE,
  CATEGORY_ROUTE,
  INBOX_ROUTE,
  MORE_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  VERIFY_OTP_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  RESET_PASSWORD_ROUTE,
  CCTV_ROUTE,
  CCTV_DETAILS_ROUTE,
  CCTV_SPLIT_ROUTE,
  TEST_ROUTE,
  MAP_DETAILS,
  INCIDENT_ROUTE,
  INCIDENT_DETAILS_ROUTE,
  INCIDENT_DETAIL_ROUTE,
  INCIDENT_FORM_ROUTE,
  SITREP_ROUTE,
  SITREP_CREATE_ROUTE,
  SITREP_DETAIL_ROUTE,
  ECM_ROUTE,
  ECM_DETAIL_ROUTE,
  WEATHER_ROUTE,
  SITREP_EDIT_ROUTE,
  WORKDECLARATION_ROUTE,
  WORKDECLARATION_CREATE_ROUTE,
  WORKDECLARATION_EDIT_ROUTE,
  WORKDECLARATION_DETAIL_ROUTE,
  INBOX_DETAIL,
  USER_DETAIL,
  INBOX_ECM_CASE_REPORT,
  CHANGE_PASSWORD_ROUTE,
  SHARE_ROUTE,
  TERMS_ROUTE,
  CONTACT_ROUTE,
  ECM_HISTORICAL_RECORD_ROUTE,
  NOTIFICATION_ROUTE,
  OHSINSPECTION_ROUTE,
  OHS_INSPECTION_CREATE_ROUTE,
  OHS_INSPECTION_DETAIL_ROUTE,
  OHS_INSPECTION_EDIT_ROUTE,
} from "./routes";
// views
import Navigation from "./components/Navigation";
import useIdleTimeout from "./components/useIdleTimeout";
import usebrowserTabCloseEvent from "./browserTabCloseEvent";
//import checkidletime from "./checkidletime";
// api
import api from "./config/api";
import config from "./config/config";
import {
  fetchCAGRWData,
  fetchHeightTemplateData,
  fetchRoadNameData,
  fetchSiteUtilData,
  fetchLampData,
  fetchLampPkgData,
  fetchGeneralConfig,
} from "../src/store/generalConfigSlice";
// firebase
import { getFirebaseToken } from "./firebase";
// css
import "@progress/kendo-theme-default/dist/all.css";
import "./App.css";
import { TermOfUse } from "./views/termofUse/term_of_use";
import { ContactUs } from "./views/more/Contactus";
import { ECM_Historical_Record } from "./views/ECM_Drain/ECM/ECM_historical_record";
import { NotificationSetting } from "./views/more/notificationSetting";
import { OHS_INSPECTION } from "./views/OHS";
import OhsInspectionForm from "./views/OHS/form";

const DashbardView = lazy(() => import("./views/dashboard/index"));
const MapView = lazy(() => import("./views/map/index"));
const MapDetailView = lazy(() => import("./views/map_detail_view"));
const CategoryView = lazy(() => import("./views/category/index"));
const InboxView = lazy(() => import("./views/inbox/index"));
const InboxECMCaseReportView = lazy(() =>
  import("./views/inbox_ecm_case_report_view")
);
const MoreView = lazy(() => import("./views/more/index"));
const CCTVView = lazy(() => import("./views/cctv/index"));
const CCTVSplitView = lazy(() => import("./views/cctv/split/cctvSplitView"));
const SitrepView = lazy(() => import("./views/Sitrep/index"));
const SitrepCreateView = lazy(() => import("./views/sitrep_create_view"));
const ECMView = lazy(() => import("./views/ecm_view"));
const WorkDeclarationView = lazy(() =>
  import("./views/work_declaration/index")
);
const WorkDeclarationCreateView = lazy(() =>
  import("./views/work_declaration_create_view")
);
const WorkDeclarationDetailView = lazy(() =>
  import("./views/work_declaration_detail_view")
);
const WeatherView = lazy(() => import("./views/weather/index"));
const ProfileView = lazy(() => import("./views/profile_view"));
const ChangePasswordView = lazy(() =>
  import("./views/authentication/change_password")
);
const ShareAppView = lazy(() => import("./views/more/share"));
const LoginView = lazy(() => import("./views/authentication/login"));
const RegisterView = lazy(() => import("./views/authentication/register"));
const VerifyOTPView = lazy(() => import("./views/authentication/verify_otp"));
const ForgotPasswordView = lazy(() =>
  import("./views/authentication/forgot_password")
);
const ResetPasswordView = lazy(() =>
  import("./views/authentication/reset_password")
);
const TestVideoView = lazy(() => import("./views/test/index"));
const IncidentView = lazy(() => import("./views/incident/index"));
const IncidentDetailView = lazy(() => import("./views/incident/details/index"));
const IncidentFormView = lazy(() =>
  import("./views/incident/form/incidentForm")
);

function App() {
  const navigate = useNavigate();
  let location = useLocation();
  let selected = location.pathname.split("/")[1] || "login";
  const idletimer = useIdleTimeout(); //checkidletime(); //useIdleTimeout();
  const browserTabCloseEvent = usebrowserTabCloseEvent();
  console.log("selected: ", selected);
  const [login, setLogin] = useState(false);
  const [logged, setLogged] = useState(false);
  const { SAVE_USER_DEVICE_TOKEN_ENDPOINT } = config.api_endpoint;
  const {
    checkTokenExpiry,
    shouldRefresh,
    refreshToken,
    signout,
    getCurrentLoggedInSessionTokenWithPromise,
    shouldRedirect,
  } = require("./config/userPool");

  const documentHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", documentHeight);
  documentHeight();

  const dispatch = useDispatch();
  const {
    cag_rw_loading,
    height_template_loading,
    road_name_loading,
    site_util_loading,
    lamp_data_loading,
    lamp_package_loading,
    general_config_loading,
  } = useSelector((state) => state.generalConfig);

  useEffect(() => {
    dispatch(fetchCAGRWData());
    dispatch(fetchHeightTemplateData());
    dispatch(fetchRoadNameData());
    dispatch(fetchSiteUtilData());
    dispatch(fetchLampData());
    dispatch(fetchLampPkgData());
    dispatch(fetchGeneralConfig());
  }, []);

  useEffect(() => {
    // shouldRedirect();
    async function saveToken(devicetoken) {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      api
        .post(
          SAVE_USER_DEVICE_TOKEN_ENDPOINT,
          JSON.stringify({
            devicetoken,
            uid: localStorage.getItem("username"),
          }),
          token
        )
        .then((res) => {
          console.log("update device token result: ", res);
          if (res.data.uid) {
            localStorage.setItem("deviceToken_id", res.data.id);
          }
        })
        .catch((err) => {
          console.log("update device token err", err);
        });
    }
    let redirect_to_dashboard = false;
    if (
      localStorage.getItem("shouldLogout") === "false" &&
      selected === "login"
    ) {
      selected = "dashboard";
      redirect_to_dashboard = true;
    }

    const ROUTES_CAN_ACCESS_WITHOUT_LOGIN = [
      REGISTER_ROUTE,
      VERIFY_OTP_ROUTE,
      FORGOT_PASSWORD_ROUTE,
      RESET_PASSWORD_ROUTE,
    ];

    if (
      selected !== "login" &&
      ROUTES_CAN_ACCESS_WITHOUT_LOGIN.indexOf(window.location.pathname) === -1
    ) {
      if (
        "Notification" in window &&
        "serviceWorker" in navigator &&
        "PushManager" in window
      ) {
        let permission = Notification.permission;
        console.log("Notification Permission:", permission);
        if (permission !== "granted") {
          Notification.requestPermission().then((perm) => {
            console.log("Notification Permission asked result:", perm);
            if (perm === "granted") {
              console.log(
                "Notification Permission is granted. Getting a firebase token."
              );
              getFirebaseToken()
                .then((firebaseToken) => {
                  console.log("Firebase token: ", firebaseToken);
                  saveToken(firebaseToken);
                })
                .catch((err) =>
                  console.error(
                    "An error occured while retrieving firebase token. ",
                    err
                  )
                );
            }
          });
          console.log("Requested for Notification Permission.");
        } else {
          console.log(
            "Notification Permission is granted. Getting a firebase token."
          );
          getFirebaseToken()
            .then((firebaseToken) => {
              console.log("Firebase token: ", firebaseToken);
              saveToken(firebaseToken);
            })
            .catch((err) =>
              console.error(
                "An error occured while retrieving firebase token. ",
                err
              )
            );
        }
      } else {
        console.warn("The browser does not support Notification, PushManager.");
      }

      setLogin(true);
      setLogged(true);
    } else {
      setLogin(false);
      setLogged(false);
    }
    if (redirect_to_dashboard) {
      window.location.href = "/dashboard";
    }
  });

  return (
    <div className="App">
      {cag_rw_loading ||
        height_template_loading ||
        road_name_loading ||
        site_util_loading ||
        lamp_data_loading ||
        lamp_package_loading ||
        general_config_loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            minHeight: "100%",
          }}
        />
      ) : login ? (
        <Navigation selected={selected} pathname={location.pathname}>
          <Suspense
            fallback={
              <Loader
                type="infinite-spinner"
                style={{
                  height: "80vh",
                  display: "flex",
                  alignItems: "center",
                  width: "40px",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  minHeight: "100%",
                }}
              />
            }
          >
            <Routes>
              <Route path={DASHBOARD_ROUTE} element={<DashbardView />} />
              <Route path={MAP_ROUTE} element={<MapView />} />
              <Route path={MAP_DETAILS} element={<MapDetailView />} />
              <Route path={CATEGORY_ROUTE} element={<CategoryView />} />
              <Route path={INBOX_ROUTE} element={<InboxView />} />
              <Route path={INBOX_DETAIL} element={<InboxView />} />
              <Route
                path={INBOX_ECM_CASE_REPORT}
                element={<InboxECMCaseReportView />}
              />
              <Route path={MORE_ROUTE} element={<MoreView />} />
              <Route path={CCTV_DETAILS_ROUTE} element={<CCTVView />} />
              <Route path={CCTV_SPLIT_ROUTE} element={<CCTVSplitView />} />
              <Route path={CCTV_ROUTE} element={<CCTVView />} />
              <Route path={TEST_ROUTE} element={<TestVideoView />} />
              <Route path={INCIDENT_ROUTE} element={<IncidentView />} />
              <Route path={INCIDENT_DETAILS_ROUTE} element={<IncidentView />} />
              <Route
                path={INCIDENT_DETAIL_ROUTE}
                element={<IncidentDetailView />}
              />
              <Route
                path={INCIDENT_FORM_ROUTE}
                element={<IncidentFormView />}
              />
              <Route path={SITREP_ROUTE} element={<SitrepView />} />
              <Route
                path={SITREP_CREATE_ROUTE}
                element={<SitrepCreateView />}
              />
              <Route path={SITREP_EDIT_ROUTE} element={<SitrepCreateView />} />
              <Route path={SITREP_DETAIL_ROUTE} element={<SitrepView />} />
              <Route path={ECM_ROUTE} element={<ECMView />} />
              <Route path={ECM_DETAIL_ROUTE} element={<ECMView />} />
              <Route
                path={ECM_HISTORICAL_RECORD_ROUTE}
                element={<ECM_Historical_Record />}
              />
              <Route
                path={WORKDECLARATION_ROUTE}
                element={<WorkDeclarationView />}
              />
              <Route
                path={WORKDECLARATION_CREATE_ROUTE}
                element={<WorkDeclarationCreateView />}
              />
              <Route
                path={WORKDECLARATION_EDIT_ROUTE}
                element={<WorkDeclarationCreateView />}
              />
              <Route
                path={WORKDECLARATION_DETAIL_ROUTE}
                element={<WorkDeclarationDetailView />}
              />
              <Route path={WEATHER_ROUTE} element={<WeatherView />} />
              <Route path={USER_DETAIL} element={<ProfileView />} />
              Weather
              <Route
                path={CHANGE_PASSWORD_ROUTE}
                element={<ChangePasswordView />}
              />
              <Route path={SHARE_ROUTE} element={<ShareAppView />} />
              <Route path={TERMS_ROUTE} element={<TermOfUse />} />
              <Route path={CONTACT_ROUTE} element={<ContactUs />} />
              <Route
                path={NOTIFICATION_ROUTE}
                element={<NotificationSetting />}
              />
              <Route path={OHSINSPECTION_ROUTE} element={<OHS_INSPECTION />} />
              <Route
                path={OHS_INSPECTION_CREATE_ROUTE}
                element={<OhsInspectionForm />}
              />
              <Route
                path={OHS_INSPECTION_EDIT_ROUTE}
                element={<OhsInspectionForm isEdit />}
              />
              <Route
                path={OHS_INSPECTION_DETAIL_ROUTE}
                element={<OhsInspectionForm isDetail />}
              />
            </Routes>
          </Suspense>
        </Navigation>
      ) : (
        <Suspense
          fallback={
            <Loader
              type="infinite-spinner"
              style={{
                height: "80vh",
                display: "flex",
                alignItems: "center",
                width: "40px",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                minHeight: "100%",
              }}
            />
          }
        >
          <Routes>
            <Route exact={true} path={LOGIN_ROUTE} element={<LoginView />} />
            <Route path={REGISTER_ROUTE} element={<RegisterView />} />
            <Route path={VERIFY_OTP_ROUTE} element={<VerifyOTPView />} />
            <Route
              path={FORGOT_PASSWORD_ROUTE}
              element={<ForgotPasswordView />}
            />
            <Route
              path={RESET_PASSWORD_ROUTE}
              element={<ResetPasswordView />}
            />
            <Route path={TEST_ROUTE} element={<TestVideoView />} />
          </Routes>
        </Suspense>
      )}
    </div>
  );
}

export default App;
