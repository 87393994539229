import { Loader } from "@progress/kendo-react-indicators";
import "./fullScreenLoader.css";
const FullscreenLoader = () => {
  return (
    <div className="fullscreen-loader">
      <Loader type="infinite-spinner" />
    </div>
  );
};

export default FullscreenLoader;
