import { useNode } from '@craftjs/core';
import cx from 'classnames';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import jsonPath from "jsonpath";
import './index.css';
import { DropdownListSettings } from './DropdownListSettings';
import { Text } from '../Text';
import { FormContext } from '../../../context/form';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import api from '../../../../../../config/api';
import evaluateVisibility from '../../utils/evaluateVisibility';
import { getParamsFromQuery, getUrlFromPath } from '../../utils/url';
import FieldLabel from '../../../FieldLabel';

const StyledDropdownList = styled.div`
  margin: ${({ margin = [] }) =>
    `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`};
  .detail-activity-list {
    flex-wrap: wrap;
  }
`;

export const DropdownList = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  // Consume form values from the provider.
  // It makes selector components tightly coupled with the parent,
  // but this is by far the most efficient way of doing it.
  const { state, token, currentUser, recordState } = useContext(FormContext)
  const [recordDetail] = recordState
  const [values, setValues] = state

  const {
    margin,
    textComponent,
    id,
    multiple = false,
    label: text,
    options: sourceOptions = [],
    apiPath: sourceApiPath,
    jsonPath: sourceJsonPath,
    visibility = [],
    disabled,
    required,
    tabID,
    meta,

    // DropdownList props
    textField,
    dataItemKey,
    relateTo,
    clearFieldsOnChange = [],
    updateApproverHandler
  } = props;
  // metadata for dropdown, e.g. a maxCount that should limit its instances by the current tabID
  // Example use case: an 'Other Observation' dropdown that should only be visible when the tabID < maxCount
  // maxCount 999 means the dropdown will always be visible
  const { maxCount = 999 } = meta || {}
  const { isVisible } = evaluateVisibility({ values, visibility, tabID, recordDetail })
  // asynchronous options state from the API
  const [asyncOptions, setAsyncOptions] = useState([])

  const selected = useMemo(
    () => values.ohs && values.ohs[props.tabID - 1] && values.ohs[props.tabID - 1].checklist && values.ohs[props.tabID - 1].checklist[id] || [],
    [values, id]
  )

  const getValue = (e) => {
    const _value = e.target.value
    // If uses custom dataItemKey, get the value from the key
    if (dataItemKey !== undefined && _value?.[dataItemKey] !== undefined) {
      return _value?.[dataItemKey]
    }
    // Otherwise, use the primitive value
    return _value
  }

  const handleOnSelect = (e) => {
    if (
      !selected.find(
        (i) => i === getValue(e)
      )
    ) {
      values.ohs[props.tabID - 1].checklist[id] = [...selected, getValue(e)]
      setValues({
        ...values,
        // [id]: [
        //   ...selected,
        //   getValue(e),
        // ]
      })
    }
  }

  const handleOnChange = (e) => {
    if ([
      "user_group",
      "significant_level_of_observation",
      "package"
    ].includes(id)) {
      setValues({
        ...values,
        [id]: getValue(e)
      })
      updateApproverHandler(id, {
        ...values,
        [id]: getValue(e)
      })
    } else if (id === "checklist") {
      // TODO: Find out why it needs to have `name` property
      if (values.ohs) {
        if (props.tabID >= 1) {
          if (values.ohs.length < props.tabID) {
            setValues({
              ...values,
              ohs: [...values.ohs, { [id]: { name: getValue(e) } }]
            })
          } else if ([undefined, null].includes(values.ohs[props.tabID - 1][id])) {
            values.ohs[props.tabID - 1][id] = {
              name: getValue(e)
            }
            setValues({
              ...values,
              // ohs: [...values.ohs, {[id]: {name: getValue(e)}}]
            })
          } else {
            if (values.ohs[props.tabID - 1]?.[id]) {
              let update = values.ohs[props.tabID - 1]
              update[id] = {
                name: getValue(e)
              };
              setValues({
                ...values
              })
            }
          }
        }
      } else {
        setValues({
          ...values,
          ohs: [{
            [id]: {
              name: getValue(e)
            }
          }]
        })
      }
    } else {
      if (values.ohs) {
        if (props.tabID >= 1) {
          if (values.ohs.length < props.tabID) {
            if (id === "other_observation") {
              if (getValue(e) === "Yes") {
                props.addForm(props.tabID)
              } else {
                props.removeForm(true, props.tabID)
              }
            }
            setValues({
              ...values,
              ohs: [...values.ohs, { [id]: getValue(e) }]
            })
          } else {
            if (values.ohs[props.tabID - 1]?.[id]) {
              if (id === "other_observation") {
                if (getValue(e) === "Yes") {
                  props.addForm(props.tabID)
                } else {
                  // props.removeForm(true, props.tabID)

                  // props.deleteForm(props.tabID)
                }
              }
              let update = values.ohs[props.tabID - 1]
              update[id] = getValue(e)
              setValues({
                ...values
              })
            } else {
              if (id === "other_observation") {
                if (getValue(e) === "Yes") {
                  props.addForm(props.tabID)
                } else {
                  // props.removeForm(true, props.tabID)

                  // props.deleteForm(props.tabID)
                }
              }
              values.ohs[props.tabID - 1][id] = getValue(e)
              setValues({
                ...values
              })
            }
          }
        }
      } else {
        if (props.tabID > 0) {
          if (id === "other_observation") {
            if (getValue(e) === "Yes") {
              props.addForm(props.tabID)
            } else {
              props.removeForm(true, props.tabID)

              // if(props.tabID > 1){
              // props.deleteForm(props.tabID)
              // }
            }
          }
          setValues({
            ...values,
            ohs: [{
              [id]: getValue(e)
            }]
          })
        } else {
          setValues({
            ...values,
            [id]: getValue(e),
          })
        }
      }
    }

    // If relateTo is defined, update the related fields with the same value
    if (relateTo?.fields?.length > 0) {
      const { fields = [], valuesMap = {} } = relateTo
      const fieldValue = getValue(e)
      fields.forEach(fieldId => {
        // Use custom value from valuesMap if available
        // otherwise use the field value
        values.ohs[props.tabID - 1][fieldId] = {
          // TODO: Find out why it needs to have `name` property
          name: valuesMap?.[fieldValue] || fieldValue
        }
        setValues({
          ...values
        })
      })
    }
  }

  // Handle dynamic apiPath for dropdown list
  const dynamicApiPath = useMemo(() => {
    // Retrieve the first match from the rules for the apiPath.
    const pathRules = Object.entries(sourceApiPath?.rules?.[0] || {})
    const [field, options] = pathRules?.[0] || []
    // Get the appropriate dynamic apiPath
    return options?.[values?.[field]]
  }, [sourceApiPath, values])

  const apiPath = useMemo(() => {
    // Simple apiPath uses string as its value
    if (typeof sourceApiPath === 'string') {
      return sourceApiPath
    }

    // Dynamic apiPath uses a 'rules' object as its value
    else if (sourceApiPath?.rules) {
      return dynamicApiPath
    }

    // Else return undefined
  }, [sourceApiPath, dynamicApiPath])

  useEffect(() => {
    // If options is empty and it has apiPath defined
    // it means that the options should be loaded asynchronously

    // Effect cleanup flag
    let optionsLoaded = false
    const loadOptions = async () => {
      let inputs
      try {
        const endpointUrl = getUrlFromPath(apiPath)
        const endpoint = endpointUrl?.pathname || apiPath
        inputs = await api.get(endpoint, {
          uid: currentUser?.["cognito:username"],
          // Append parameters from the endpointUrl's query
          ...getParamsFromQuery(endpointUrl)
        }, token)

        // Extract data with jsonPath if defined
        if (sourceJsonPath) {
          inputs = jsonPath.query(inputs, sourceJsonPath)
        }

        // Transform the responses into array because the doRequest somehow turns API responses into object.
        // Dropdownlist accepts array as the options property.
        if (inputs && !Array.isArray(inputs)) {
          const { status, status_code, ...objArray } = inputs
          inputs = Object.values(objArray)
        }

        // If the API returns empty array, create a default option;
        // This is to prevents the app from reloading the API
        if (inputs?.length === 0) {
          inputs = [
            'Not available'
          ]
        }
      } catch {
        // TODO: Catch exception
        inputs = [
          'Not available'
        ]
      } finally {
        optionsLoaded = true
        setAsyncOptions(inputs)
      }
    }

    if (
      // load if it has not been loaded yet
      !optionsLoaded &&
      // and it has empty options from the source
      sourceOptions.length === 0 &&
      // and it has not populated the asynchronous options yet
      asyncOptions.length === 0 &&
      // and the apiPath is valid
      !!apiPath
    ) {
      loadOptions()
    }

    // Clean up asynchronous operation
    return () => {
      optionsLoaded = true
    }
  }, [sourceOptions, asyncOptions, apiPath, sourceJsonPath, token, currentUser])

  useEffect(() => {
    // Reset async options when dynamic path changes
    // so that it re-retrieve the options
    setAsyncOptions([])
  }, [dynamicApiPath])

  const options = useMemo(() => {
    const dropdownByRule = opt => {
      // Show dynamic option based on its visibility
      if (opt?.visibility?.length > 0) {
        const { isVisible: shouldShowOption } = evaluateVisibility({
          values,
          visibility: opt?.visibility,
          tabID,
          recordDetail,
        })
        return shouldShowOption()
      }

      // If it does not have visibility, show it
      return true
    }

    // If local options are available, use it
    if (sourceOptions.length > 0) {
      return sourceOptions.filter(dropdownByRule)
    }

    // Otherwise use options loaded remotely
    return asyncOptions.filter(dropdownByRule)
  }, [sourceOptions, asyncOptions])


  const getSelectedValue = useCallback((selectedValue) => {
    console.log(props.value, "this is props value");
    if(props.value) return props.options.find(val => val.id === props.value)
    const _value = selectedValue
    // If uses custom dataItemKey, get the object value
    try {
      const selectedValue = options.find(opt => opt?.[dataItemKey] === _value)
      if (dataItemKey !== undefined && selectedValue) {
        return selectedValue
      }

      // throw so that it uses the fallback value
      throw ('No value')
    } catch { }
    // Otherwise, use the primitive value
    return _value
  }, [values, options])

  useEffect(() => {
    if (!isVisible()) {
      if (values.ohs) {
        if (values.ohs[props.tabID - 1] && values.ohs[props.tabID - 1][id]) {
          delete values.ohs[props.tabID - 1][id]
          setValues({
            ...values
          })
        }
      }
    }
    if (isVisible()) {
      if (required) {
        const cases =
          props.tabID === 0
            ? (id === "user_group" || id === "package" || id === "significant_level_of_observation") &&
              (values?.[id] === undefined || values?.[id] === "")
              ? true
              : false
            : values.ohs === undefined
              ? true
              : id === "hira"
                ? values.ohs[props.tabID - 1]
                  ? values.ohs[props.tabID - 1]["checklist"]
                    ? values.ohs[props.tabID - 1]["checklist"]?.[id]
                      ? values.ohs[props.tabID - 1]["checklist"]?.[id].length === 0
                        ? true
                        : false
                      : true
                    : true
                  : true
                : id === "checklist"
                  ? values.ohs[props.tabID - 1]
                    ? values.ohs[props.tabID - 1].checklist
                      ? values.ohs[props.tabID - 1][id].name === undefined ||
                        values.ohs[props.tabID - 1][id].name === ""
                        ? true
                        : false
                      : true
                    : true
                  : values.ohs[props.tabID - 1] &&
                    values.ohs[props.tabID - 1][id] !== undefined
                    ? values.ohs[props.tabID - 1][id] === ""
                      ? true
                      : false
                    : true
                      ? true
                      : false;
        if (cases === true) {
          props.checkValidation(true, `${id} ${props.tabID}`);
        } else {
          props.checkValidation(false, `${id} ${props.tabID}`);
        }
        // switch(cases){
        //   case true:
        //   case false:
        //   default: props.checkValidation(true, `${id} ${props.tabID}`);
        // }
      }
    } else {
      props.checkValidation(false, `${id} ${props.tabID}`);
    }

    if(required && !isVisible()){
      props.checkValidation(false, `${id} ${props.tabID}`);
    }
  })

  const multipleDelete = (opt) => {
    if (values.ohs[props.tabID - 1].checklist) {
      values.ohs[props.tabID - 1].checklist[id] = selected.filter(
        (i) => i !== opt);
      setValues({
        ...values,
      })
    }
  }

  const clearFields = () => {
    if (clearFieldsOnChange.length > 0) {
      clearFieldsOnChange.forEach(cf => {
        // Currently it clears fields from the sub details.
        // TODO: Allow clearing fields from main details.
        if (values.ohs && values.ohs.length > 0) {
          values.ohs.forEach((detail, index) => {
            if (detail?.[cf] !== undefined) {
              delete values.ohs[index][cf]
            }
          })
        }

        setValues({
          ...values,
        })
      })
    }
  }

  const visible = isVisible() && (
    tabID < maxCount
  )

  return visible
    ? (
      <StyledDropdownList
        ref={connect}
        className={cx([
          'w-full detail-item-box field-validation__wrapper',
        ])}
        margin={margin}
      >
        <FieldLabel {...textComponent} text={text} required={props.isMandaotry? props.isMandaotry :required} />
        <div className="w-full max-w-[90vw]">
          <DropDownList
            data={options}
            className="selector--dropdown"
            value={
              multiple
                // multiple mode displays values underneath the dropdown
                ? 'Select'
                : getSelectedValue(
                  props.tabID >= 1
                    ? id === "checklist"
                      ? values.ohs && values.ohs[props.tabID - 1] && values.ohs[props.tabID - 1].checklist && values.ohs[props.tabID - 1].checklist.name
                      : values.ohs && values.ohs[props.tabID - 1]?.[id]
                    : values?.[id]
                )
            }
            onChange={e => {
              clearFields()

              if (multiple) {
                handleOnSelect(e)
              } else {
                handleOnChange(e)
              }
            }}
            disabled={id === "other_observation" ? values.ohs && values.ohs[props.tabID - 1]?.[id] === "Yes" ? true : false : disabled}
            {...{
              textField,
              dataItemKey,
            }}
          />
          {
            multiple
              ? (
                <div className="detail-activity-list mt-2">
                  {
                    selected.map((opt, index) => (
                      <div
                        key={`${opt}-${index}`}
                        className="flex items-center justify-center gap-2"
                      >
                        {opt}{" "}
                        <span
                          onClick={() => {
                            multipleDelete(opt)
                            // setValues({
                            //   ...values,
                            //   [id]: selected.filter(
                            //     (i) => i !== opt
                            //   ),
                            // });
                          }}
                          className="w-[16px] h-[16px] flex items-center justify-center cursor-pointer"
                        >
                          X
                        </span>
                      </div>
                    ))
                  }
                </div>
              )
              : null
          }
          {isVisible() && required && props.tabID >= 1 && (
            <>
              {values.ohs === undefined ?
                (<div className='OHS_FORM_validation'>
                  This field is required!</div>) :
                id === "hira" ?
                  values.ohs && values.ohs[props.tabID - 1]["checklist"] && (values.ohs[props.tabID - 1]["checklist"]?.[id] === undefined || values.ohs[props.tabID - 1]["checklist"]?.[id] === "") ?
                    (<div className='OHS_FORM_validation'>This field is required!</div>) :
                    values.ohs && values.ohs[props.tabID - 1]["checklist"] &&
                    Array.isArray(values.ohs[props.tabID - 1]["checklist"]?.[id]) &&
                    values.ohs[props.tabID - 1]["checklist"]?.[id].length === 0 &&
                    (<div className='OHS_FORM_validation'>This field is required!</div>) :
                  values.ohs && (values.ohs[props.tabID - 1]?.[id] === undefined || values.ohs[props.tabID - 1]?.[id] === "") ?
                    (<div className='OHS_FORM_validation'>
                      This field is required!</div>) :
                    values.ohs && Array.isArray(values.ohs[props.tabID - 1]?.[id]) ?
                      values.ohs[props.tabID - 1]?.[id].length === 0 &&
                      (<div className='OHS_FORM_validation'>This field is required!</div>)
                      : null}
            </>
          )}
          {isVisible() && required && props.tabID === 0 ? (values?.[id] === undefined || values?.[id] === "") && (<div className='OHS_FORM_validation'>
            This field is required!</div>) : ""}
        </div>
      </StyledDropdownList>
    )
    : null;
};

DropdownList.craft = {
  displayName: 'Dropdown List',
  props: {
    margin: ['5', '0', '5', '0'],
    placeholder: 'Enter a value...',
    id: '',
    text: 'Field label',
    textComponent: {
      ...Text.craft.props,
    },
  },
  related: {
    toolbar: DropdownListSettings,
  },
};
