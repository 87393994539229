import { Loader } from "@progress/kendo-react-indicators"

const LoadingIndicator = (props) => (
    <Loader
        type="infinite-spinner"
        className="flex items-center justify-center m-auto w-[40px] h-screen"
        {...props}
    />
)

export default LoadingIndicator