import { Text } from "../form_designer/selectors"

const FieldLabel = (props) => {
    return (
        <div className="detail-item-label">
            <Text {...props} />
        </div>
    )
}

export default FieldLabel