// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { GET_MAP_LAYER_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: true,
  mapLayerImage: [],
  error: null,
};

export const fetchAllMapLayerImage = createAsyncThunk(
  "mapLayerImage/fetchALLMapLayerImage",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.post(
        GET_MAP_LAYER_ENDPOINT,
        JSON.stringify({}),
        token
      );
      console.log("MapLayerImage", results);
      return {
        mapLayerImage: results.items,
      };
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const mapLayerImageSlice = createSlice({
  name: "mapLayerImage",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllMapLayerImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllMapLayerImage.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.mapLayerImage = action.payload;
      })
      .addCase(fetchAllMapLayerImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const MapLayerImageReducer = mapLayerImageSlice.reducer;
export default MapLayerImageReducer;
