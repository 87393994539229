const evaluateEditability = ({ values, editability, tabID, recordDetail }) => {
  const getFieldValue = (field) => {
    // TODO: Find out where this .ohs prop is defined
    const ohsValues = Array.isArray(values?.ohs) ? values.ohs : values;
    // In detail mode, record details are stored in the first observation array
    const detailFieldValue = ohsValues?.[0]?.[field]
    let fieldValueInTab = ohsValues?.[tabID - 1]?.[field];

    // TODO: Find out where .name prop is defined
    // Get field value in tab from either the name property or its own value
    fieldValueInTab = fieldValueInTab?.name !== undefined
      ? fieldValueInTab?.name
      : fieldValueInTab;

    // If the field value in tab is undefined, it must be part of the root values
    const fieldValueInRoot = values?.[field]

    // field value in a specific tab
    if (fieldValueInTab !== undefined) {
      return fieldValueInTab
      // field value from record details
    } else if (detailFieldValue !== undefined) {
      return detailFieldValue
    }
    // field value from main details
    return fieldValueInRoot
  };

  // Handle element editability by other fields' value
  const isEditable = () => {
    if (editability?.length > 0) {
      // By default, editability are chained by OR operator (.some)
      // TODO: handle other chain, such as AND operator (.every)
      return editability.some((rule) => {
        const { field, value, operator, stage } = rule;

        // If the rule contains a stage value, compare the value with
        // the record detail's status (i.e. data.status is one of the stage value)
        if (stage?.length > 0) {
          let recordStatus = recordDetail?.status || 'draft'
          return stage.includes(recordStatus)
        }

        const fieldValue = getFieldValue(field);
        switch (operator) {
          // Value equals to
          case "=":
          default:
            return fieldValue === value;
          // Value is not nullish
          case "notNull":
            return ![null, undefined].includes(fieldValue);
        }
      });
    }

    // If there are no editability, make it editable
    return true;
  };

  return {
    isEditable,
  };
};

export default evaluateEditability;
