import React from "react";
import { OHS_SIDEBAR_MOBILE } from "./OHS_Mobile/ohs_sidebar";
import "./ohs.css";
import { useMediaQuery } from "react-responsive";
import { OHS_SIDEBAR_WEB } from "./OHS_Web/ohs_sidebar";

export const OHS_INSPECTION = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return <div>{isMobile ? <OHS_SIDEBAR_MOBILE /> : <OHS_SIDEBAR_WEB />}</div>;
};
