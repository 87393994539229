import config from "./config";
import api from "./api";
const { client_id, pool_id, identityPoolId, region } = require("../service");
const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

const { Get_USER_ENDPOINT } = config.api_endpoint;

export const poolData = {
  UserPoolId: pool_id, // Your user pool id here
  ClientId: client_id, // Your client id here
};

export const checkTokenExpiry = () => {
  const userPool = getPool();
  const cognitoUser = userPool.getCurrentUser();
  let expiresIn = null;
  if (cognitoUser) {
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
        return null;
      }
      const currentTime = Math.floor(Date.now() / 1000);
      expiresIn = session.getAccessToken().getExpiration() - currentTime;
      console.log(`Access token expires in ${expiresIn} seconds`);
    });
  } else {
    console.log("No user found");
  }
  return expiresIn;
};

export const shouldRefresh = (expiry) => {
  // refresh if it's within 5 mins
  if (expiry < 300) {
    return true;
  }
  return false;
};

export const refreshToken = () => {
  // Redirect the user to the login form
  const userPool = getPool();
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.log(err);
        return;
      }
      cognitoUser.refreshSession(session.getRefreshToken(), (err, session) => {
        if (err) {
          console.log(err);
          if (err.message === "Refresh token has expired") {
            // Refresh token has expired, prompt the user to sign in again
            console.log("Refresh token has expired, please sign in again");
          } else {
            // Handle other errors
            console.log("Error refreshing tokens:", err);
          }
          return;
        }
        console.log("Access token has been refreshed");
        console.log("New access token expiry: ", checkTokenExpiry());
      });
    });
  } else {
    console.log("No user found");
  }
};

export const refreshTokenIfNecessary = () => {
  const expiresIn = checkTokenExpiry();
  console.log("Access token expires in: ", expiresIn);
  if (expiresIn !== null) {
    const should_refresh = shouldRefresh(expiresIn);
    console.log("Should refresh access token: ", should_refresh);
    if (should_refresh) {
      refreshToken();
    }
  }
};

export const requrieRefresh = () => {
  const expiresIn = checkTokenExpiry();
  console.log("Access token expires in: ", expiresIn);
  if (expiresIn !== null) {
    const should_refresh = shouldRefresh(expiresIn);
    console.log("Should refresh access token: ", should_refresh);
    if (should_refresh) {
      return true;
    }
  }
  return false;
};

export const getPool = () => {
  return new AmazonCognitoIdentity.CognitoUserPool(poolData);
};

export const getAuthDetails = (username, password) => {
  return new AmazonCognitoIdentity.AuthenticationDetails({
    Username: username,
    Password: password,
  });
};
export const getCognitoUser = (username) => {
  // var username = localStorage.getItem("username");
  var username = localStorage.getItem("CognitoIdentityServiceProvider.1usgdnqkl2n29j3plvpvab8oj9.LastAuthUser")
  var userData = {
    Username: username,
    Pool: getPool(),
  };
  return new AmazonCognitoIdentity.CognitoUser(userData);
};

export const signout = () => {
  const username = localStorage.getItem("CognitoIdentityServiceProvider.1usgdnqkl2n29j3plvpvab8oj9.LastAuthUser")
  var userData = {
    Username: username,
    Pool: getPool(),
  };
  console.log("userData in pool", userData);
  console.log("username in pool", username);
  console.log("pool in userPool", getPool());
  var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  cognitoUser.signOut();
  localStorage.removeItem("current_user");
};

export const shouldRedirect = () => {
  console.log(getPool());
  const username = localStorage.getItem("CognitoIdentityServiceProvider.1usgdnqkl2n29j3plvpvab8oj9.LastAuthUser")
  // const username = localStorage.getItem("username");
  console.log(new AmazonCognitoIdentity.CognitoUserPool(poolData));
  var userData = {
    Username: username,
    Pool: getPool(),
  };
  try {
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  } catch (e) {
    console.log(e);
  }
};

const doGetIdTokenPayload = (callback) => {
  const userPool = getPool();
  const cognitoUser = userPool.getCurrentUser();
  cognitoUser.getSession((error, session) => {
    if (error) {
      callback(error, null);
    } else {
      if (requrieRefresh()) {
        cognitoUser.refreshSession(session.getRefreshToken(), (error) => {
          if (error) {
            callback(error, null);
          } else {
            const idToken = session.getIdToken().payload;
            callback(null, idToken);
          }
        });
      } else {
        const idToken = session.getIdToken().payload;
        callback(null, idToken);
      }
    }
  });
};

export const getCurrentLoggedInUserWithPromise = () => {
  return new Promise((resolve, reject) => {
    doGetIdTokenPayload((error, token) => {
      if (error) {
        reject(error);
      } else {
        resolve(token);
      }
    });
  });
};

export const getCurrentLoggedInUser = () => {
  // refreshTokenIfNecessary();
  const userPool = getPool();
  const cognitoUser = userPool.getCurrentUser();
  let CurrentidToken = null;
  if (cognitoUser != null) {
    CurrentidToken = cognitoUser.getSession(function (err, session) {
      if (err) {
        console.log(err.message);
      } else {
        var idToken = session.getIdToken().payload;
        console.log("UserPool: " + idToken["custom:company"]);

        if (idToken != null) {
          api
            .get(
              Get_USER_ENDPOINT,
              {
                uid: idToken["sub"],
              },
              getCurrentLoggedInSessionToken()
            )
            .then((res) => {
              console.log(res.data);
              idToken["custom:company"] =
                res.data["company"][0]["company_name"];

              if (localStorage.getItem("company")) {
                localStorage.removeItem("company");
              }

              localStorage.setItem(
                "company",
                res.data["company"][0]["company_name"]
              );
            });
        }

        return idToken;
      }
    });
  } else {
    console.log("Session expired. Please log in again.");
  }

  return CurrentidToken;
};

const doGetJWTIdToken = (callback) => {
  const userPool = getPool();
  const cognitoUser = userPool.getCurrentUser();
  cognitoUser.getSession((error, session) => {
    if (error) {
      callback(error, null);
    } else {
      if (requrieRefresh()) {
        cognitoUser.refreshSession(session.getRefreshToken(), (error) => {
          if (error) {
            callback(error, null);
          } else {
            const idToken = session.getIdToken().getJwtToken();
            callback(null, idToken);
          }
        });
      } else {
        const idToken = session.getIdToken().getJwtToken();
        callback(null, idToken);
      }
    }
  });
};

export const getCurrentLoggedInSessionTokenWithPromise = () => {
  return new Promise((resolve, reject) => {
    doGetJWTIdToken((error, token) => {
      if (error) {
        reject(error);
      } else {
        resolve(token);
      }
    });
  });
};

export const getCurrentLoggedInSessionToken = () => {
  // refreshTokenIfNecessary();
  const userPool = getPool();
  const cognitoUser = userPool.getCurrentUser();
  let CurrentidToken = null;
  if (cognitoUser != null) {
    CurrentidToken = cognitoUser.getSession(function (err, session) {
      if (err) {
        console.log(err.message);
      } else {
        var idToken = session.getIdToken().getJwtToken();
        // console.log("ID TOKEN: ", session.getIdToken());
        return idToken;
      }
    });
  } else {
    console.log("Session expired. Please log in again.");
  }
  return CurrentidToken;
};
