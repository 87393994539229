import { useNode } from '@craftjs/core';
import cx from 'classnames';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { CheckListSettings } from './CheckListSettings';
import { Text } from '../Text';
import { FormContext } from '../../../context/form';
import FieldLabel from '../../../FieldLabel';

const StyledCheckList = styled.div`
  margin: ${({ margin = [] }) =>
    `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`};
`;

export const CheckList = (props) => {
  const {
    connectors: { connect },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  // Consume form values from the provider.
  // It makes selector components tightly coupled with the parent,
  // but this is by far the most efficient way of doing it.
  const { state } = useContext(FormContext)
  const [values, setValues] = state

  const {
    margin,
    textComponent,
    id,
    label: text,
    dataItemKey = 'id',
    textField = 'label',
    descField = 'description',
    options = [],
    disabled,
    required,
  } = props;
  const selected = useMemo(
    () => values?.[id] || [],
    [values, id]
  )
  return (
    <StyledCheckList
      ref={connect}
      className={cx([
        'w-full detail-item-box field-validation__wrapper',
      ])}
      margin={margin}
    >
      <FieldLabel {...textComponent} text={text} required={required} />
      <div className="hira-items w-full">
        {
          options.map((opt, index) => (
            <div
              className="detail-radio-div width-100"
              key={index}
            >
              <>
                <div
                  className="w-[24px]"
                  onClick={() => {

                    if (disabled) {
                      return
                    }
                    if (
                      selected.find(
                        (d) => d === opt?.[dataItemKey]
                      )
                    ) {
                      const filter =
                        selected.filter(
                          (d) => d !== opt?.[dataItemKey]
                        );
                      // handleOnChange(filter, 1)
                      setValues({
                        ...values,
                        [id]: filter,
                      });
                    } else {
                      setValues({
                        ...values,
                        [id]: [
                          ...selected,
                          opt?.[dataItemKey],
                        ]
                      })
                    }
                  }}
                >
                  <label className="work-container">
                    <input
                      type="checkbox"
                      disabled
                      checked={
                        selected.find(
                          (d) => d === opt?.[dataItemKey]
                        )
                          ? true
                          : false
                      }
                    />
                    <span
                      className={"work-checkmark"}
                    ></span>
                  </label>
                </div>
                <div style={{ width: "calc(100% - 24px)" }}>
                  <div className="type-form-label">
                    {index + 1}. {opt?.[textField]}
                  </div>
                  <div className="type-form-label-child">
                    {opt?.[descField]}
                  </div>
                </div>
              </>
            </div>
          ))
        }
      </div>
    </StyledCheckList>
  );
};

CheckList.craft = {
  displayName: 'Checklist',
  props: {
    margin: ['5', '0', '5', '0'],
    placeholder: 'Enter a value...',
    id: '',
    text: 'Field label',
    textComponent: {
      ...Text.craft.props,
    },
  },
  related: {
    toolbar: CheckListSettings,
  },
};
