import React, { useEffect, useRef, useState } from "react";
import MobileHeaderFiltered from "../../../components/mobileHeaderFiltered/mobileHeaderFiltered";
import {
  CATEGORY_ROUTE,
  OHS_INSPECTION_CREATE_ROUTE,
  OHS_INSPECTION_DETAIL_ROUTE,
} from "../../../routes";
import LeftArrowIcon from "../../../assets/images/incident/left-arrow.svg";
import RightArrowIcon from "../../../assets/images/incident/right-arrow.svg";
import DownArrowIcon from "../../../assets/images/OHS/DownArrow.svg";
import RightArrowDetail from "../../../assets/images/OHS/Right_arrow.svg";
import PlusIcon from "../../../assets/images/incident/plus.svg";
import FilterIcon from "../../../assets/images/cctv/filter.svg";
import SearchIcon from "../../../assets/images/OHS/SearchIcon.svg";
import { useNavigate } from "react-router";
import { OHS_Filter } from "../OHS_Filter";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../../config/userPool";
import api from "../../../config/api";
import config from "../../../config/config";
import {
  getUrlFromPath,
  getParamsFromQuery,
} from "../form/form_designer/utils/url";
import jsonPath, { value } from "jsonpath";
import moment from "moment";
import { Loader } from "@progress/kendo-react-indicators";
import OHSListSearch from "../OHS_Filter/search";

export const OHS_SIDEBAR_MOBILE = () => {
  const statusBarRef = useRef(null);
  const navigate = useNavigate();
  let current_user = JSON.parse(localStorage.getItem("current_user"));
  const { GET_OHS_ENDPOINT, GET_OHS_FILTER_ENDPOINT, OHS_EXPORT } =
    config.api_endpoint;
  const { VIEW_OHS, VIEW_OHS_LIMITED, CREATE_OHS, CREATE_OHS_LIMITED } =
    config.permissions;
  const [showFilter, setShowFilter] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [mainLoading, setMainLoading] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [loading, setLoading] = useState(false);
  const [expand, setExpand] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showCreateBtn, setShowCreateBtn] = useState(false);
  const [statusCount, setStatusCount] = useState({});
  const [filterError, setFilterError] = useState({
    isError: false,
    startDate: "",
    endDate: "",
  });
  const [filterValue, setFilterValue] = useState({
    user_group: "",
    package: [],
    work_zone: "",
    type_of_work_activity: "",
    status: "open",
    source_of_observation: "",
    significant_level_of_observation: "",
    type_of_risk: "",
    company_carrying: "",
    hazards: "",
    from_date: "",
    to_date: "",
    potential_consequences: "",
    search_text: "",
  });
  const [filterLoader, setFilterLoader] = useState(false);

  const filterURL = [
    {
      id: "work_zone",
      apiPath: "/api-ohs/input-selection?key=Work Zone",
    },
    {
      id: "type_of_work_activity",
      apiPath: "/api-ohs/input-selection?key=Type of Work Activity",
    },
    {
      id: "package",
      apiPath: "/api-users/getUser",
      jsonPath: "$.data.package[*]",
    },
    {
      id: "company_carrying",
      apiPath: "/api-work-declaration/work_declaration/company",
      jsonPath: "$.data[*]",
    },
    {
      id: "hazards",
      apiPath: "/api-ohs/input-selection?key=Hazards",
    },
    {
      id: "potential_consequences",
      apiPath: "/api-ohs/input-selection?key=Potential Consequences",
    },
  ];

  const handleShowFilter = () => {
    setShowFilter(true);
  };
  const handleSearchIconClicked = () => {
    setShowSearchBox(!showSearchBox);
  };

  let props = {
    status: "open",
    count: {
      draft: 1,
      open: 2,
      for_rectification: 3,
      for_approval: 4,
      closed: 5,
      not_valid: 6,
    },
  };

  const shouldAccessAll = (user) => {
    if (user.permissions.indexOf(VIEW_OHS) > -1) {
      return true;
    } else if (user.permissions.indexOf(VIEW_OHS_LIMITED) > -1) {
      return false;
    }
    return false;
  };

  const icons = [
    {
      logo: SearchIcon,
      handleClicked: handleSearchIconClicked,
      show: true,
    },
    {
      logo: FilterIcon,
      show: true,
      handleClicked: handleShowFilter,
    },
  ];

  const leftArrowClicked = () => {
    console.log("leftArrowClicked: ", statusBarRef.current);
    statusBarRef.current.scrollBy({ top: 0, left: -100, behavior: "smooth" });
  };

  const rightArrowClicked = () => {
    console.log("rightArrowClicked: ", statusBarRef.current);
    statusBarRef.current.scrollBy({ top: 0, left: 100, behavior: "smooth" });
  };

  const filterDataAPI = async (url) => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    try {
    } catch (err) {
      console.log(err);
    }
    const endpointUrl = getUrlFromPath(url.apiPath);
    const endpoint = endpointUrl?.pathname || url.apiPath;
    let inputs = await api.get(
      endpoint,
      {
        uid: current_user["cognito:username"],
        // Append parameters from the endpointUrl's query
        ...getParamsFromQuery(endpointUrl),
      },
      token
    );
    if (url.jsonPath) {
      inputs = jsonPath.query(inputs, url.jsonPath);
    }
    if (inputs && !Array.isArray(inputs)) {
      const { status, status_code, ...objArray } = inputs;
      inputs = Object.values(objArray);
    }
    if (inputs?.length === 0) {
      inputs = ["Not available"];
    }

    return inputs;
  };

  const onChangeFilter = async (value, type) => {
    if (type === "user_group") {
      setFilterLoader(true);
      const url =
        value === "Project Team"
          ? {
              apiPath: "/api-ohs/input-selection?key=Source of Observation 1",
            }
          : {
              apiPath: "/api-ohs/input-selection?key=Source of Observation 2",
            };
      const result = await filterDataAPI(url);
      console.log(result);
      if (result) {
        setFilterData({
          ...filterData,
          source_of_observation: result,
        });
        setFilterLoader(false);
      }
      setFilterValue({
        ...filterValue,
        [type]: value,
        source_of_observation: "",
      });
    } else if (type === "from_date" || type === "to_date") {
      dateErrorChecker(value, type);
      setFilterValue({
        ...filterValue,
        [type]: value,
      });
    } else {
      setFilterValue({
        ...filterValue,
        [type]: value,
      });
    }
  };

  const getFilterParams = (status) => {
    let current_user = JSON.parse(localStorage.getItem("current_user"));
    let filterParams = {
      page: 1,
      page_size: 99999999,
      package: !shouldAccessAll(current_user)
        ? current_user["package"].join(",")
        : "",
      created_uid: localStorage.getItem("username"),
    };

    if (filterValue.to_date !== "" && filterValue.from_date === "") {
      setFilterError({
        ...filterError,
        isError: true,
        startDate: "Start date field need!",
        endDate: "",
      });
      return;
    }
    if (filterValue.from_date !== "" && filterValue.to_date === "") {
      setFilterError({
        ...filterError,
        isError: true,
        startDate: "",
        endDate: "End date field need!",
      });
      return;
    }
    if (filterValue.from_date > filterValue.to_date) {
      setFilterError({
        ...filterError,
        isError: true,
        startDate: "Start date should not be later than End date!",
        endDate: "End date should not be earlier than Start date!",
      });
      return;
    }
    if (filterValue.search_text !== "") {
      filterParams = {
        ...filterParams,
        text: filterValue.search_text,
      };
    }
    if (filterValue.from_date !== "") {
      filterParams = {
        ...filterParams,
        start_date: `${moment(filterValue.from_date).format("YYYY-MM-DD")}`,
      };
    }
    if (filterValue.to_date !== "") {
      filterParams = {
        ...filterParams,
        end_date: `${moment(filterValue.to_date).format("YYYY-MM-DD")}`,
      };
    }
    if (filterValue.package !== "") {
      filterParams = {
        ...filterParams,
        package:
          filterValue.package.length > 0
            ? filterValue.package.find((i) => i === "All")
              ? !shouldAccessAll(current_user)
                ? current_user["package"].join(",")
                : ""
              : filterValue.package.join(",")
            : !shouldAccessAll(current_user)
            ? current_user["package"].join(",")
            : "",
      };
    }
    if (filterValue.user_group !== "") {
      filterParams = {
        ...filterParams,
        user_group: filterValue.user_group,
      };
    }
    if (filterValue.work_zone !== "") {
      filterParams = {
        ...filterParams,
        work_zone: filterValue.work_zone,
      };
    }
    if (filterValue.type_of_work_activity !== "") {
      filterParams = {
        ...filterParams,
        type_of_work_activity: filterValue.type_of_work_activity,
      };
    }
    if (filterValue.type_of_risk !== "") {
      filterParams = {
        ...filterParams,
        type_of_risk: filterValue.type_of_risk,
      };
    }
    if (status) {
      filterParams = {
        ...filterParams,
        status: status,
      };
    } else {
      if (filterValue.status !== "") {
        filterParams = {
          ...filterParams,
          status: filterValue.status,
        };
      }
    }
    if (filterValue.source_of_observation !== "") {
      filterParams = {
        ...filterParams,
        source_of_observation: filterValue.source_of_observation,
      };
    }
    if (filterValue.significant_level_of_observation !== "") {
      filterParams = {
        ...filterParams,
        significant_level_of_observation:
          filterValue.significant_level_of_observation,
      };
    }
    if (filterValue.company_carrying !== "") {
      filterParams = {
        ...filterParams,
        company_carrying: filterValue.company_carrying,
      };
    }
    if (filterValue.hazards !== "") {
      filterParams = {
        ...filterParams,
        hazards: filterValue.hazards,
      };
    }
    if (filterValue.potential_consequences !== "") {
      filterParams = {
        ...filterParams,
        potential_consequences: filterValue.potential_consequences,
      };
    }
    return filterParams;
  };

  const filterApply = async () => {
    if (getFilterParams() === undefined) return;
    setLoading(true);
    setShowFilter(false);

    const token = await getCurrentLoggedInSessionTokenWithPromise();
    console.log(getFilterParams());
    api.get(GET_OHS_FILTER_ENDPOINT, getFilterParams(), token).then((res) => {
      let dataItem = [];
      res.data.items.map((value) => {
        if (dataItem.find((i) => i.package === value.package)) {
          const find = dataItem.find((i) => i.package === value.package);
          if (!find.item.find((i) => i.id === value.id)) {
            find.item.push({
              ...value,
            });
          }
        } else {
          dataItem.push({
            package: value.package,
            item: [{ ...value }],
          });
        }
      });
      setStatusCount(res.data.count);
      setListingData([...dataItem]);
      setLoading(false);
    });
  };

  const filterClear = async (search) => {
    let current_user = JSON.parse(localStorage.getItem("current_user"));
    setLoading(true);
    if (search) {
      setFilterValue({
        ...filterValue,
        search_text: "",
      });
      let filterParams = {
        ...getFilterParams(),
      };
      if (filterParams.text) {
        delete filterParams.text;
      }
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const newPage = 1;
      api.get(GET_OHS_FILTER_ENDPOINT, filterParams, token).then((res) => {
        let dataItem = [];
        res.data.items.map((value) => {
          if (dataItem.find((i) => i.package === value.package)) {
            const find = dataItem.find((i) => i.package === value.package);
            if (!find.item.find((i) => i.id === value.id)) {
              find.item.push({
                ...value,
              });
            }
          } else {
            dataItem.push({
              package: value.package,
              item: [{ ...value }],
            });
          }
        });
        setStatusCount(res.data.count);
        setListingData([...dataItem]);
        setLoading(false);
      });
    } else {
      setShowFilter(false);
      let textString = filterValue.search_text;
      let copyStatus = filterValue.status;
      setFilterValue({
        user_group: "",
        package: [],
        work_zone: "",
        status: copyStatus,
        type_of_work_activity: "",
        source_of_observation: "",
        significant_level_of_observation: "",
        type_of_risk: "",
        company_carrying: "",
        hazards: "",
        from_date: "",
        to_date: "",
        potential_consequences: "",
        search_text: textString,
      });
      let filterParams = {
        page: 1,
        page_size: 15,
        status: copyStatus,
        package: !shouldAccessAll(current_user)
          ? current_user["package"].join(",")
          : "",
        created_uid: localStorage.getItem("username"),
      };
      if (textString !== "") {
        filterParams = {
          ...filterParams,
          text: textString,
        };
      }
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const newPage = 1;
      api.get(GET_OHS_FILTER_ENDPOINT, filterParams, token).then((res) => {
        let dataItem = [];
        res.data.items.map((value) => {
          if (dataItem.find((i) => i.package === value.package)) {
            const find = dataItem.find((i) => i.package === value.package);
            if (!find.item.find((i) => i.id === value.id)) {
              find.item.push({
                ...value,
              });
            }
          } else {
            dataItem.push({
              package: value.package,
              item: [{ ...value }],
            });
          }
        });
        setStatusCount(res.data.count);
        setListingData([...dataItem]);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    async function getData() {
      setMainLoading(true);
      let filterAPICall = {
        significant_level_of_observation: [
          "Compliance",
          "Observation",
          "Significant Observation",
          "No Activity",
        ],
        type_of_risk: ["Aerodrome", "Environmental", "WSH"],
      };
      await filterURL.map(async (url) => {
        const result = await filterDataAPI(url);
        if (result) {
          filterAPICall = { ...filterAPICall, [url.id]: result };
          setFilterData(filterAPICall);
        }
      });
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const userData = JSON.parse(localStorage.getItem("current_user"));
      if (
        userData.permissions.filter((x) =>
          [CREATE_OHS, CREATE_OHS_LIMITED].includes(x)
        ).length > 0
      ) {
        setShowCreateBtn(true);
      }
      api
        .get(
          GET_OHS_FILTER_ENDPOINT,
          {
            page: 1,
            page_size: 99999,
            package: !shouldAccessAll(current_user)
              ? current_user["package"].join(",")
              : "",
            status: filterValue.status,
            created_uid: localStorage.getItem("username"),
          },
          token
        )
        .then((res) => {
          console.log(res);
          let dataItem = [];
          res.data.items.map((value) => {
            if (dataItem.find((i) => i.package === value.package)) {
              const find = dataItem.find((i) => i.package === value.package);
              if (!find.item.find((i) => i.id === value.id)) {
                find.item.push({
                  ...value,
                });
              }
            } else {
              dataItem.push({
                package: value.package,
                item: [{ ...value }],
              });
            }
          });
          setStatusCount(res.data.count);
          setListingData([...dataItem]);
          setShowFilter(false);
          setMainLoading(false);
        });
    }
    getData();
  }, []);

  const showMore = (value) => {
    if (expand.find((i) => i === value)) {
      const filter = expand.filter((i) => i !== value);
      setExpand([...filter]);
    } else {
      expand.push(value);
      setExpand([...expand]);
    }
  };
  const sorting = () => {
    return function (a, b) {
      if (a.package === b.package) {
        return 0;
      }
      if (a.package === "Others") {
        return 1;
      }
      if (b.package === "Others") {
        return -1;
      }
      return a.package < b.package ? -1 : 1;
    };
  };

  const statusChange = async (status) => {
    setLoading(true);
    setFilterValue({
      ...filterValue,
      status: status,
    });
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .get(GET_OHS_FILTER_ENDPOINT, getFilterParams(status), token)
      .then((res) => {
        let dataItem = [];
        res.data.items.map((value) => {
          if (dataItem.find((i) => i.package === value.package)) {
            const find = dataItem.find((i) => i.package === value.package);
            if (!find.item.find((i) => i.id === value.id)) {
              find.item.push({
                ...value,
              });
            }
          } else {
            dataItem.push({
              package: value.package,
              item: [{ ...value }],
            });
          }
        });
        setStatusCount(res.data.count);
        setListingData([...dataItem]);
        setLoading(false);
      });
  };

  const dateErrorChecker = (value, type) => {
    if (type === "from_date") {
      let date = value;
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      if (filterValue.to_date !== "" && filterValue.to_date < date) {
        setFilterError({
          ...filterError,
          isError: true,
          startDate: "Start date should not be later than End date!",
          endDate: "End date should not be earlier than Start date!",
        });
      }
      if (filterValue.to_date === "") {
        setFilterError({
          ...filterError,
          isError: true,
          startDate: "",
          endDate: "End date field need!",
        });
      }
      if (filterValue.to_date !== "" && filterValue.to_date > date) {
        setFilterError({
          ...filterError,
          isError: false,
          startDate: "",
          endDate: "",
        });
      }
    } else {
      let date = value;
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      // setEndDate(date);
      if (filterValue.from_date !== "" && date < filterValue.from_date) {
        setFilterError({
          ...filterError,
          isError: true,
          startDate: "Start date should not be later than End date!",
          endDate: "End date should not be earlier than Start date!",
        });
      }
      if (filterValue.from_date === "") {
        setFilterError({
          ...filterError,
          isError: true,
          endDate: "",
          startDate: "Start date field need!",
        });
      }
      if (filterValue.from_date !== "" && date > filterValue.from_date) {
        setFilterError({
          ...filterError,
          isError: false,
          endDate: "",
          startDate: "",
        });
      }
    }
  };
  const keyDownHandler = (e) => {
    console.log(e.key);
    if (e.key === "Enter") {
      filterApply();
    }
  };
  const createPage = () => {
    navigate(OHS_INSPECTION_CREATE_ROUTE);
  };
  return (
    <>
      {mainLoading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div style={{ borderRight: "1px solid #E4E1DE" }}>
          <MobileHeaderFiltered
            title={"OHS Inspection "}
            route={CATEGORY_ROUTE}
            Class={"OHS_header"}
            icons={icons}
          />
          {showFilter && (
            <OHS_Filter
              filterData={filterData}
              setShowFilter={setShowFilter}
              onChangeFilter={onChangeFilter}
              filterValue={filterValue}
              filterLoader={filterLoader}
              filterApply={filterApply}
              filterClear={filterClear}
              status={false}
              filterError={filterError}
            />
          )}
          {showSearchBox && (
            <OHSListSearch
              onChangeFilter={onChangeFilter}
              filterValue={filterValue}
              filterApply={filterApply}
              filterClear={filterClear}
              keyDownHandler={keyDownHandler}
            />
          )}
          <div
            className="OHS-slider-items-wrapper"
            style={{
              height: showSearchBox
                ? "calc(var(--doc-height) - 177px)"
                : "calc(var(--doc-height) - 122px)",
            }}
          >
            <React.Fragment>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: "63px",
                }}
              >
                <div
                  style={{
                    height: "63px",
                    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.12)",
                    display: "flex",
                    alignItems: "center",
                    width: "25px",
                    background: "white",
                    order: 1,
                    cursor: "pointer",
                  }}
                  onClick={leftArrowClicked}
                >
                  <div
                    style={{ width: "100%" }}
                    // className="incident-list-filter-left-arrow"
                  >
                    <img src={LeftArrowIcon} alt="Left icon" />
                  </div>
                </div>
                <div
                  className="OHS_chips_box"
                  style={{ order: 2 }}
                  ref={statusBarRef}
                >
                  <div
                    className={
                      filterValue.status === "draft"
                        ? "OHS_chips_sec_box OHS_chips_sec_box_active"
                        : "OHS_chips_sec_box OHS_chips_sec_box_unactive"
                    }
                    style={{
                      background: filterValue.status === "draft" && "grey",
                    }}
                    onClick={() => statusChange("draft")}
                    // onClick={() => {
                    //   setItems(0);

                    //   if (searchTxtStr.length > 0) {
                    //     props.CallAPIFilter(
                    //       "approved",
                    //       searchTxtStr,
                    //       allFilters.startDate,
                    //       allFilters.endDate,
                    //       allFilters
                    //     );
                    //   } else if (
                    //     allFilters.startDate !== "" ||
                    //     allFilters.endDate !== "" ||
                    //     // allFilters.HIRA !== "" ||
                    //     allFilters.package !== "" ||
                    //     allFilters.company !== "" ||
                    //     allFilters.routine !== ""
                    //   ) {
                    //     props.CallAPIFilter(
                    //       "approved",
                    //       searchTxtStr,
                    //       allFilters.startDate,
                    //       allFilters.endDate,
                    //       allFilters
                    //     );
                    //   } else {
                    //     props.CallAPI("approved");
                    //   }
                    // }}
                  >
                    <div
                      className={
                        filterValue.status === "draft"
                          ? "OHS_chips_text OHS_chips_text_active"
                          : "OHS_chips_text OHS_chips_text_unactive"
                      }
                    >
                      Draft
                    </div>
                    {/*   filterValue.status === "approved" && ( */}
                    {statusCount && statusCount.draft !== 0 && (
                      <div
                        className={
                          filterValue.status === "draft"
                            ? "OHS_chips_text1 OHS_chips_text1_active"
                            : "OHS_chips_text1 OHS_chips_text1_unactive"
                        }
                        style={{
                          color: filterValue.status === "draft" && "grey",
                        }}
                      >
                        {/* {props.count && props.count.approved} */}{" "}
                        {statusCount && statusCount.draft}
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      filterValue.status === "open"
                        ? "OHS_chips_sec_box OHS_chips_sec_box_active"
                        : "OHS_chips_sec_box OHS_chips_sec_box_unactive"
                    }
                    style={{
                      background: filterValue.status === "open" && "#FFFF00",
                    }}
                    onClick={() => statusChange("open")}

                    // onClick={() => {
                    //   setItems(1);

                    //   if (searchTxtStr.length > 0) {
                    //     props.CallAPIFilter(
                    //       "pending_approval",
                    //       searchTxtStr,
                    //       allFilters.startDate,
                    //       allFilters.endDate,
                    //       allFilters
                    //     );
                    //   } else if (
                    //     allFilters.startDate !== "" ||
                    //     allFilters.endDate !== "" ||
                    //     // allFilters.HIRA !== "" ||
                    //     allFilters.package !== "" ||
                    //     allFilters.company !== "" ||
                    //     allFilters.routine !== ""
                    //   ) {
                    //     props.CallAPIFilter(
                    //       "pending_approval",
                    //       searchTxtStr,
                    //       allFilters.startDate,
                    //       allFilters.endDate,
                    //       allFilters
                    //     );
                    //   } else {
                    //     props.CallAPI("pending_approval");
                    //   }
                    // }}
                  >
                    <div
                      className={
                        filterValue.status === "open"
                          ? "OHS_chips_text OHS_chips_text_active"
                          : "OHS_chips_text OHS_chips_text_unactive"
                      }
                      style={{
                        color: filterValue.status === "open" && "#5C5146",
                      }}
                    >
                      Open
                    </div>
                    {/*   filterValue.status === "pending_approval" && ( */}
                    {statusCount && statusCount.open !== 0 && (
                      <div
                        className={
                          filterValue.status === "open"
                            ? "OHS_chips_text1 OHS_chips_text1_active"
                            : "OHS_chips_text1 OHS_chips_text1_unactive"
                        }
                        style={{
                          color: filterValue.status === "open" && "black",
                        }}
                      >
                        {/* {props.count && props.count.open} */}
                        {statusCount && statusCount.open}
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      filterValue.status === "for_rectification"
                        ? "OHS_chips_sec_box OHS_chips_sec_box_active"
                        : "OHS_chips_sec_box OHS_chips_sec_box_unactive"
                    }
                    style={{
                      background:
                        filterValue.status === "for_rectification" && "#e06666",
                    }}
                    onClick={() => statusChange("for_rectification")}

                    // onClick={() => {
                    //   setItems(2);

                    //   if (searchTxtStr.length > 0) {
                    //     props.CallAPIFilter(
                    //       "draft",
                    //       searchTxtStr,
                    //       allFilters.startDate,
                    //       allFilters.endDate,
                    //       allFilters
                    //     );
                    //   } else if (
                    //     allFilters.startDate !== "" ||
                    //     allFilters.endDate !== "" ||
                    //     // allFilters.HIRA !== "" ||
                    //     allFilters.package !== "" ||
                    //     allFilters.company !== "" ||
                    //     allFilters.routine !== ""
                    //   ) {
                    //     props.CallAPIFilter(
                    //       "draft",
                    //       searchTxtStr,
                    //       allFilters.startDate,
                    //       allFilters.endDate,
                    //       allFilters
                    //     );
                    //   } else {
                    //     props.CallAPI("draft");
                    //   }
                    // }}
                  >
                    <div
                      className={
                        filterValue.status === "for_rectification"
                          ? "OHS_chips_text OHS_chips_text_active"
                          : "OHS_chips_text OHS_chips_text_unactive"
                      }
                    >
                      For Rectification
                    </div>
                    {/*   filterValue.status === "draft" && ( */}
                    {statusCount && statusCount.for_rectification !== 0 && (
                      <div
                        className={
                          filterValue.status === "for_rectification"
                            ? "OHS_chips_text1 OHS_chips_text1_active"
                            : "OHS_chips_text1 OHS_chips_text1_unactive"
                        }
                        style={{
                          color:
                            filterValue.status === "for_rectification" &&
                            "#e06666",
                        }}
                      >
                        {statusCount && statusCount.for_rectification}
                      </div>
                    )}
                  </div>

                  <div
                    className={
                      filterValue.status === "for_approval"
                        ? "OHS_chips_sec_box OHS_chips_sec_box_active"
                        : "OHS_chips_sec_box OHS_chips_sec_box_unactive"
                    }
                    style={{
                      background:
                        filterValue.status === "for_approval" && "#FFFF00",
                    }}
                    onClick={() => statusChange("for_approval")}
                    // onClick={() => {
                    //   setItems(3);

                    //   if (searchTxtStr.length > 0) {
                    //     props.CallAPIFilter(
                    //       "closed",
                    //       searchTxtStr,
                    //       allFilters.startDate,
                    //       allFilters.endDate,
                    //       allFilters
                    //     );
                    //   } else if (
                    //     allFilters.startDate !== "" ||
                    //     allFilters.endDate !== "" ||
                    //     // allFilters.HIRA !== "" ||
                    //     allFilters.package !== "" ||
                    //     allFilters.company !== "" ||
                    //     allFilters.routine !== ""
                    //   ) {
                    //     props.CallAPIFilter(
                    //       "closed",
                    //       searchTxtStr,
                    //       allFilters.startDate,
                    //       allFilters.endDate,
                    //       allFilters
                    //     );
                    //   } else {
                    //     props.CallAPI("closed");
                    //   }
                    // }}
                  >
                    <div
                      className={
                        filterValue.status === "for_approval"
                          ? "OHS_chips_text OHS_chips_text_active"
                          : "OHS_chips_text OHS_chips_text_unactive"
                      }
                      style={{
                        color:
                          filterValue.status === "for_approval" && "#5C5146",
                      }}
                    >
                      For Approval
                    </div>
                    {/*   filterValue.status === "closed" && ( */}
                    {statusCount && statusCount.for_approval !== 0 && (
                      <div
                        className={
                          filterValue.status === "for_approval"
                            ? "OHS_chips_text1 OHS_chips_text1_active"
                            : "OHS_chips_text1 OHS_chips_text1_unactive"
                        }
                        style={{
                          color:
                            filterValue.status === "for_approval" && "black",
                        }}
                      >
                        {statusCount && statusCount.for_approval}
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      filterValue.status === "closed"
                        ? "OHS_chips_sec_box OHS_chips_sec_box_active"
                        : "OHS_chips_sec_box OHS_chips_sec_box_unactive"
                    }
                    style={{
                      background: filterValue.status === "closed" && "#b7d7a8",
                    }}
                    onClick={() => statusChange("closed")}
                    // onClick={() => {
                    //   setItems(4);
                    //   if (searchTxtStr.length > 0) {
                    //     props.CallAPIFilter(
                    //       "rejected",
                    //       searchTxtStr,
                    //       allFilters.startDate,
                    //       allFilters.endDate,
                    //       allFilters
                    //     );
                    //   } else if (
                    //     allFilters.startDate !== "" ||
                    //     allFilters.endDate !== "" ||
                    //     // allFilters.HIRA !== "" ||
                    //     allFilters.package !== "" ||
                    //     allFilters.company !== "" ||
                    //     allFilters.routine !== ""
                    //   ) {
                    //     props.CallAPIFilter(
                    //       "rejected",
                    //       searchTxtStr,
                    //       allFilters.startDate,
                    //       allFilters.endDate,
                    //       allFilters
                    //     );
                    //   } else {
                    //     props.CallAPI("rejected");
                    //   }
                    // }}
                  >
                    <div
                      className={
                        filterValue.status === "closed"
                          ? "OHS_chips_text OHS_chips_text_active"
                          : "OHS_chips_text OHS_chips_text_unactive"
                      }
                    >
                      Closed
                    </div>
                    {/*   filterValue.status === "rejected" && ( */}
                    {statusCount && statusCount.closed !== 0 && (
                      <div
                        className={
                          filterValue.status === "closed"
                            ? "OHS_chips_text1 OHS_chips_text1_active"
                            : "OHS_chips_text1 OHS_chips_text1_unactive"
                        }
                        style={{
                          color: filterValue.status === "closed" && "#b7d7a8",
                        }}
                      >
                        {statusCount && statusCount.closed}
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      filterValue.status === "not_valid"
                        ? "OHS_chips_sec_box OHS_chips_sec_box_active"
                        : "OHS_chips_sec_box OHS_chips_sec_box_unactive"
                    }
                    style={{
                      background:
                        filterValue.status === "not_valid" && "#94c37d",
                    }}
                    onClick={() => statusChange("not_valid")}
                    // onClick={() => {
                    //   setItems(4);
                    //   if (searchTxtStr.length > 0) {
                    //     props.CallAPIFilter(
                    //       "rejected",
                    //       searchTxtStr,
                    //       allFilters.startDate,
                    //       allFilters.endDate,
                    //       allFilters
                    //     );
                    //   } else if (
                    //     allFilters.startDate !== "" ||
                    //     allFilters.endDate !== "" ||
                    //     // allFilters.HIRA !== "" ||
                    //     allFilters.package !== "" ||
                    //     allFilters.company !== "" ||
                    //     allFilters.routine !== ""
                    //   ) {
                    //     props.CallAPIFilter(
                    //       "rejected",
                    //       searchTxtStr,
                    //       allFilters.startDate,
                    //       allFilters.endDate,
                    //       allFilters
                    //     );
                    //   } else {
                    //     props.CallAPI("rejected");
                    //   }
                    // }}
                  >
                    <div
                      className={
                        filterValue.status === "not_valid"
                          ? "OHS_chips_text OHS_chips_text_active"
                          : "OHS_chips_text OHS_chips_text_unactive"
                      }
                    >
                      Not Valid
                    </div>
                    {/*   filterValue.status === "rejected" && ( */}
                    {statusCount && statusCount.not_valid !== 0 && (
                      <div
                        className={
                          filterValue.status === "not_valid"
                            ? "OHS_chips_text1 OHS_chips_text1_active"
                            : "OHS_chips_text1 OHS_chips_text1_unactive"
                        }
                        style={{
                          color:
                            filterValue.status === "not_valid" && "#94c37d",
                        }}
                      >
                        {statusCount && statusCount.not_valid}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    height: "63px",
                    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.12)",
                    display: "flex",
                    alignItems: "center",
                    width: "25px",
                    background: "white",
                    order: 3,
                    cursor: "pointer",
                  }}
                  onClick={rightArrowClicked}
                >
                  <div style={{ width: "100%" }}>
                    <img src={RightArrowIcon} alt="Right icon" />
                  </div>
                </div>
              </div>
            </React.Fragment>
            {loading ? (
              <Loader
                type="infinite-spinner"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "40px",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  height: "calc(100vh - 185px)",
                }}
              />
            ) : (
              <div style={{ marginTop: "10px" }} className="Scroll_main_box">
                {listingData.length > 0 &&
                  listingData.sort(sorting()).map((data, id) => (
                    <div key={id}>
                      <div
                        className="OHS_Sidebar_list_main_box"
                        onClick={() => showMore(data.package)}
                      >
                        <div className="OHS_Sidebar_list_main_box_title">
                          {data.package}
                        </div>
                        <div>
                          <img
                            // style={{ transform: "rotate(180deg)" }}
                            src={DownArrowIcon}
                            alt="Down icon"
                          />
                        </div>
                      </div>
                      {expand.find((i) => i === data.package) && (
                        <>
                          {/* {[1].map((i) => (
                      <div className="OHS_Sidebar_list_child_box_main">
                        <div className="OHS_Sidebar_list_child_box_child">
                          <div style={{ color: "#52C865" }}>●</div>
                          <div className="OHS_Sidebar_list_child_box_text_box">
                            <div className="OHS_Sidebar_list_child_box_text_title">
                              OHS Inspection_001
                            </div>
                            <div className="OHS_Sidebar_list_child_box_text_detail">
                              Zone 1
                            </div>
                          </div>
                        </div>
                        <div>
                          <img src={RightArrowDetail} alt="Right icon" />
                        </div>
                      </div>
                    ))} */}
                          {data.item.map((i, id) => (
                            <div
                              className="OHS_Sidebar_list_child_box_main"
                              key={id}
                              onClick={() =>
                                navigate(
                                  `${OHS_INSPECTION_DETAIL_ROUTE}?id=${i.id}`
                                )
                              }
                            >
                              <div className="OHS_Sidebar_list_child_box_text_title">
                                {i.user_group !== null ? i.user_group : "-"}
                              </div>
                              <div>
                                <div className="OHS_Sidebar_list_child_box_child">
                                  <div className="OHS_Sidebar_list_child_box_text_detail_info Color_GREY">
                                    Source of Observation
                                  </div>
                                  <div className="OHS_Sidebar_list_child_box_text_detail_info">
                                    {i.source_of_observation !== null
                                      ? i.source_of_observation
                                      : "-"}
                                  </div>
                                </div>
                                <div className="OHS_Sidebar_border" />
                                <div className="OHS_Sidebar_list_child_box_child">
                                  <div className="OHS_Sidebar_list_child_box_text_detail_info Color_GREY">
                                    Company carrying out work activity
                                  </div>
                                  <div className="OHS_Sidebar_list_child_box_text_detail_info">
                                    {i.company_carrying !== null
                                      ? i.company_carrying
                                      : "-"}
                                  </div>
                                </div>
                                <div className="OHS_Sidebar_border" />
                                <div className="OHS_Sidebar_list_child_box_child">
                                  <div className="OHS_Sidebar_list_child_box_text_detail_info Color_GREY">
                                    Date
                                  </div>
                                  <div className="OHS_Sidebar_list_child_box_text_detail_info">
                                    {i.date_of_observation !== null
                                      ? moment(
                                          new Date(i.date_of_observation)
                                        ).format("YYYY-MM-DD")
                                      : "-"}
                                  </div>
                                </div>
                                <div className="OHS_Sidebar_border" />
                                <div className="OHS_Sidebar_list_child_box_child">
                                  <div className="OHS_Sidebar_list_child_box_text_detail_info Color_GREY">
                                    Work Zone
                                  </div>
                                  <div className="OHS_Sidebar_list_child_box_text_detail_info">
                                    {i.work_zone !== null ? (
                                      <span
                                        style={{
                                          fontSize: "20px",
                                          marginRight: "15px",
                                          color:
                                            i.work_zone === "Airside"
                                              ? "#a0c5e8"
                                              : i.work_zone ===
                                                "Landside (Contractor's worksite and premises)"
                                              ? "rgb(148 195 125)"
                                              : "rgb(204 204 204)",
                                        }}
                                      >
                                        ●
                                      </span>
                                    ) : null}
                                    {i.work_zone !== null ? i.work_zone : "-"}
                                  </div>
                                </div>
                                <div className="OHS_Sidebar_border" />
                                <div className="OHS_Sidebar_list_child_box_child">
                                  <div className="OHS_Sidebar_list_child_box_text_detail_info Color_GREY">
                                    Significant level of observation
                                  </div>
                                  <div className="OHS_Sidebar_list_child_box_text_detail_info">
                                    {i.significant_level_of_observation !==
                                    null ? (
                                      <span
                                        style={{
                                          marginRight: "15px",
                                          fontSize: "20px",
                                          color:
                                            i.significant_level_of_observation ===
                                            "Compliance"
                                              ? "#d4edbb"
                                              : i.significant_level_of_observation ===
                                                "Observation"
                                              ? "#ffe59f"
                                              : i.significant_level_of_observation ===
                                                "Significant Observation"
                                              ? "#b10202"
                                              : "#e6e6e6",
                                        }}
                                      >
                                        ●
                                      </span>
                                    ) : null}
                                    {i.significant_level_of_observation !== null
                                      ? i.significant_level_of_observation
                                      : "-"}
                                  </div>
                                </div>
                                <div className="OHS_Sidebar_border" />
                                <div className="OHS_Sidebar_list_child_box_child">
                                  <div className="OHS_Sidebar_list_child_box_text_detail_info Color_GREY">
                                    Type of risk
                                  </div>
                                  <div className="OHS_Sidebar_list_child_box_text_detail_info">
                                    {i.type_of_risk !== null ? (
                                      <span
                                        style={{
                                          marginRight: "15px",
                                          fontSize: "20px",
                                          color:
                                            i.type_of_risk === "Aerodrome"
                                              ? "#c0e1f6"
                                              : i.type_of_risk ===
                                                "Environmental"
                                              ? "#d4edbb"
                                              : "#ffe59f",
                                        }}
                                      >
                                        ●
                                      </span>
                                    ) : null}
                                    {i.type_of_risk !== null
                                      ? i.type_of_risk
                                      : "-"}
                                  </div>
                                </div>
                                <div className="OHS_Sidebar_border" />
                                <div className="OHS_Sidebar_list_child_box_child">
                                  <div className="OHS_Sidebar_list_child_box_text_detail_info Color_GREY">
                                    Type of Work Activity
                                  </div>
                                  <div className="OHS_Sidebar_list_child_box_text_detail_info">
                                    {i.type_of_work_activity !== null
                                      ? i.type_of_work_activity
                                      : "-"}
                                  </div>
                                </div>
                                <div className="OHS_Sidebar_border" />
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  ))}
              </div>
            )}
            {showCreateBtn && (
              <div style={{ position: "relative" }}>
                <div className="OHS_Sidebar_plus_icon" onClick={createPage}>
                  <img src={PlusIcon} alt="Add icon" />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
