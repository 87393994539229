const evaluateValidity = (props) => {
  const { isRequired, isVisible } = props
  const isValid = (value) => {
    // Only evaluate validity if the field is visible and required
    if (isVisible && isRequired) {
      // Valid is when the value is not null or undefined or blank
      return ![null, undefined, ''].includes(value)
    }

    // Valid by default, if it is either invisible or not a required field
    return true
  };

  return {
    isValid,
  };
};

export default evaluateValidity;
